/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import SVG from "react-inlinesvg";
import { getUserInfo } from "../../../../../utils/user.util";

export function AsideMenuList({ layoutProps }: any) {
  const location = useLocation();
  let userInfo = getUserInfo();
  const getMenuItemActive = (url: any, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"
      } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {userInfo?.position === "Super Admin" && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/dashboard",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <div style={{ stroke: "green" }}>
                  <SVG className="custom-filling"
                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                  />
                </div>
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/admins", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admins">
              <span className="svg-icon menu-icon">
                {/* <img alt="" src="media/allIconsForTable/feedback.svg" /> */}
                <i className="fa-solid fa-user" style={{ color: "#383839" }}></i>
              </span>
              <span className="menu-text">Admins</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/all-courses", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/all-courses">
              <span className="svg-icon menu-icon">
                {/* <img alt="" src="media/allIconsForTable/dashboard.svg" /> */}
                <i className="fa-solid fa-list " style={{ color: "#383839" }}></i>
              </span>
              <span className="menu-text">Alle Modules</span>
              {/* <span className="menu-text">All Course</span> */}
            </NavLink>
          </li>
        </ul>
      )}

      {userInfo?.position === "Admin" && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/dashboard",
              true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/users", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/users">
              <span className="svg-icon menu-icon">
                <i className="fa-solid fa-user" style={{ color: "#383839" }}></i>
              </span>
              {/* <span className="menu-text">Users</span> */}
              <span className="menu-text">Benutzer</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/courses", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/courses">
              <span className="svg-icon menu-icon">
                <i className="fa-solid fa-list" style={{ color: "#383839" }}></i>
              </span>
              <span className="menu-text">Module</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/course-category", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/course-category">
              <span className="svg-icon menu-icon">
                <i className="fa-solid fa-list" style={{ color: "#383839" }}></i>
              </span>
              {/* <span className="menu-text">Course category</span> */}
              <span className="menu-text">Module Kategorie</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/bord-name", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/bord-name">
              <span className="svg-icon menu-icon">
                <i className="fa-solid fa-circle-info" style={{ color: "#383839" }}></i>
              </span>
              {/* <span className="menu-text">Board </span> */}
              <span className="menu-text">To Do Board </span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/create-session", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/create-session">
              <span className="svg-icon menu-icon">
                <i className="fas fa-video" style={{ color: "#383839" }}></i>
              </span>
              {/* <span className="menu-text">Board </span> */}
              <span className="menu-text">Sitzung erstellen </span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/notification", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/notification">
              <span className="svg-icon menu-icon">              
              <i className="fa fa-bell" style={{ color: "#383839"}}></i>
              </span>
              {/* <span className="menu-text">Board </span> */}
              <span className="menu-text">
                {/* Notification */}Benachrichtigung
              </span>
            </NavLink>
          </li>
          {/* <li
            className={`menu-item ${getMenuItemActive("/session", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/session">
              <span className="svg-icon menu-icon">
                <img alt="" src="/media/allIconsForTable/info.svg" />
              </span>
              <span className="menu-text">Create Session </span>
            </NavLink>
          </li>    */}
        </ul>
      )}

      {userInfo?.position === "Team Leader" && (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG className="custom-filling"
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/users", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/users">
              <span className="svg-icon menu-icon">
                <i className="fa-solid fa-user" style={{ color: "#383839" }}></i>
              </span>
              <span className="menu-text">
                {/* Users */}Benutzer
              </span>
            </NavLink>
          </li>

          <li
            className={`menu-item ${getMenuItemActive("/bord-name", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/bord-name">
              <span className="svg-icon menu-icon">
                <i className="fa-solid fa-circle-info" style={{ color: "#383839" }}></i>
              </span>
              {/* <span className="menu-text">Board </span> */}
              <span className="menu-text">To Do Board  </span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/create-session", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/create-session">
              <span className="svg-icon menu-icon">
                <i className="fas fa-video" style={{ color: "#383839" }}></i>
              </span>
              {/* <span className="menu-text">Board </span> */}
              <span className="menu-text">Sitzung erstellen
              </span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/notification", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/notification">
              <span className="svg-icon menu-icon">
                <i className="fa fa-bell" style={{ color: "#383839" }}></i>
              </span>
              {/* <span className="menu-text">Board </span> */}
              <span className="menu-text">
                {/* Notification */}Benachrichtigung
              </span>
            </NavLink>
          </li>
        </ul>
      )}

      </>
  );
}
