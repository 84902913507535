import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { customStyles } from "../Courses/TableStyle";
import { useHistory } from "react-router-dom";

const AllUsers = () => {
  let userInfo = getUserInfo();

  const [isType, setIsType] = useState<any>();
  const [inputValue, setInputValue] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [inputValueForAdd, setInputValueForAdd] = useState<any>()
  const [isEditApi, setIsEditApi] = useState<any>(false);
  const [idForEditType, setIdForEditType] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [show, setShow] = useState<any>(false);
  const [Showmodel, setShowmodel] = useState<any>(); //for view progress courses modal
  const [Showmodellesson, setShowlessonmodel] = useState<any>(); //for view progress lesson modal
  const [progressCourseData, setProgressCourseData] = useState<any>();
  const [progressLessonData, setProgresslessonData] = useState<any>();
  const [filterUserData, setFilterUserData] = useState<any>();
  const [IdforDelete, setIdForDelete] = useState<any>({});
  const [userModal, setUsermodal] = useState<any>();//for view team leader's user modal
  const [modalUser, setmodalUser] = useState<any>([]);
  const [assignedUser, setAssignedUser] = useState<any>();
  const [onlyTL, setOnlyTL] = useState<any>()

  const regexEmail =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

  const handleshowCourseModel = (row: any) => {
    setShowmodel(true);
  };

  const handleUserShowModal = (row: any) => {
    setUsermodal(true)
  }

  const handleshowlessonModel = (row: any) => {
    console.log("rowrowrow", row?.lessonView);
    setShowmodel(true);
    setShowlessonmodel(true);
    setProgresslessonData(row?.lessonView);
  };
  const history = useHistory();

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    console.log("eeee", value);
    if (name === "fname" && value.charAt(e.length) == " ") return;
    else if (name === "lname" && value.charAt(e.length) == " ") return;
    else if (name === "radio") {
      setAssignedUser(e.target.value)
      setErrors({ ...errors, [name]: "" });
    } else {
      setInputValue({ ...inputValue, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
  };

  const Validation = () => {
    let error: any = {};
    let Formvalid = true;
    if (!inputValue.fname) {
      Formvalid = false;
      error["fname"] = "Vorname ist nötig";
    }
    if (!inputValue.lname) {
      Formvalid = true;
      error["lname"] = "Nachname ist nötig";
    }
    if (!inputValue.email) {
      Formvalid = true;
      error["email"] = "E-Mail ist nötig";
    }
    if (regexEmail.test(inputValue.email) === false) {
      Formvalid = true;
      error["email"] = "E-Mail ist ungültig";
    }
    if (!inputValue.phone) {
      Formvalid = true;
      error["phone"] = "Telefonnummer ist nötig";
    }
    if (!inputValue.password) {
      Formvalid = true;
      error["password"] = "Passwort ist nötig";
    }
    // if (userInfo?.position === "Admin" && !inputValue.position) {
    //   Formvalid = true;
    //   error["position"] = "Position ist nötig";
    // }
    if (!assignedUser || assignedUser === "") {
      Formvalid = true;
      error["radio"] = "Dies ist erforderlich";
    }
    if (assignedUser === "true" && !inputValue.tlid) {
      Formvalid = true;
      error["tlid"] = "Teamleiter wird nicht ausgewählt";
    }
    setErrors(error);
    return Formvalid;
  };

  const handleClose = () => {
    setShow(false);
    setShowmodel(false);
    setUsermodal(false)
    setShowlessonmodel(false);
  };

  const handleAddInvoice = () => {
    setIsType(false);
    setIsEditApi(false);
    setErrors({});
    setInputValue({});
    setAssignedUser("")
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (userInfo?.position === "Team Leader") {
      document.title = " Akademie | Teamleiter";
    }
    else {
      document.title = " Akademie | Admin";
    }

  }, []);

  const handleSearchData = (e: any) => {
    var value = e.target.value.toLowerCase();
    setUserData(() =>
      filterUserData?.filter(
        (item: any) =>
          item?.fname?.toLowerCase().includes(value) ||
          item?.lname?.toLowerCase().includes(value) ||
          item?.email?.toLowerCase().includes(value)
      )
    )
  }

  const getAllUsers = async () => {
    setLoading(true);
    if (userInfo?.position === "Team Leader") {
      await ApiGet(`admin/getUserByTlid/${userInfo?._id}`)
        .then((res: any) => {
          if (res?.status === 200) {
            setLoading(false);
            console.log("getuser", res?.data?.payload?.user);
            res.data.payload.user.map((e: any, index: any) => {
              e.index = index + 1;
              return e;
            });
            setUserData(res?.data?.payload?.user);
            setFilterUserData(res?.data?.payload?.user);
          } else {
            setLoading(false)
            toast.error(res?.data?.message)
          }
        })
        .catch((err) => {
          setLoading(false)
          toast.error(err?.res?.data?.message)
          console.log("err", err?.response);
        })
    }
    else {
      await ApiGet(`admin/getUserByAid/${userInfo?._id}`)
        .then((res: any) => {
          setLoading(false);
          console.log("getuser", res?.data?.payload?.user);
          res.data.payload.user.map((e: any, index: any) => {
            e.index = index + 1;
            return e;
          });
          setUserData(res?.data?.payload?.user);
          setFilterUserData(res?.data?.payload?.user);
          setOnlyTL(res?.data?.payload?.user?.filter((item: any, index: any) => {
            return item?.position === "Team Leader"
          }))
        })
        .catch((err) => {
          setLoading(false)
          toast.error(err?.res?.data?.message)
          console.log("err", err);
        });
    }
  };

  const getShowUser = async (_id: any) => {
    setLoading(true);
    await ApiGet(`admin/getUserByTlid/${_id}`)
      .then((res: any) => {
        if (res?.status === 200) {
          setLoading(false);
          res.data.payload.user.map((e: any, index: any) => {
            e.index = index + 1;
            return e;
          });
          setmodalUser(res?.data?.payload?.user)
          console.log("@@@@@@@", res);
        }
        else {
          setLoading(false)
          toast.error(res?.data?.message)
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err?.res?.data?.message)
        console.log("err", err);
      });
  };

  const getShowCourses = async (uid: any) => {
    setLoading(true);
    await ApiGet(`progress/get-last-lesson?uid=${uid}`)
      .then((res: any) => {
        setLoading(false);
        console.log("get Progress courses", res?.data?.payload?.courseView[0]?.lessonView);
        res.data.payload.courseView.map((e: any, index: any) => {
          e.index = index + 1;
          return e;
        });
        setProgressCourseData(res?.data?.payload?.courseView);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const getShowlesson = async (uid: any) => {
  //   await ApiGet(`progress/get-last-lesson?uid=${uid}`)
  //     .then((res: any) => {
  //       console.log("get Progress lesson", res?.data?.payload?.lessonView);
  //       res.data.payload.lessonView.map((e: any, index: any) => {
  //         e.index = index + 1;
  //         return e;
  //       });
  //       setProgresslessonData(res?.data?.payload?.lessonView);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  const addUser = async (e: any) => {
    if (userInfo?.position === "Admin") {
      inputValue.aid = userInfo?._id;
      inputValue.role = "61aa0369803e260c3821ad0a";
    }
    if (userInfo?.position === "Team Leader") {
      inputValue.position = "User";
      inputValue.tlid = userInfo?._id
      inputValue.aid = userInfo?.aid
      inputValue.role = "62c2af37d17a303524a9222d"
    }
    if (Validation()) {
      setLoading(true);
      await ApiPost(`admin/signup`, inputValue)
        .then((res: any) => {
          setLoading(false);
          if (res?.status === 200) {
            toast.success("Benutzer erfolgreich hinzugefügt", { theme: "colored" });
            console.log("add type", res);
            setInputValue({});
            setIsType(false);
            getAllUsers();
            setInputValueForAdd({});
          } else {
            toast.error(res?.data?.message, { theme: "colored" });
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log("err", err);
          toast.error(err?.response?.data?.message, { theme: "colored" });
        });
    }
  };

  const editUser = async (e: any) => {
    setLoading(true);
    await ApiPut(`admin/updateAdmin/${idForEditType}`, inputValue)
      .then((res: any) => {
        setLoading(false);
        if (res?.status === 200) {
          toast.success("Benutzer erfolgreich bearbeitet", { theme: "colored" });
          console.log("editType", res);
          setIsType(false);
          getAllUsers();
          setInputValue({});
          setInputValueForAdd({});
          setIsEditApi(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };
  const Deletuser = async () => {
    await ApiDelete(`admin/deleteAdmin/${IdforDelete}`, inputValue)
      .then((res: any) => {
        console.log("status res", res);
        if (res?.status === 200) {
          setShow(false);
          toast.success("Daten erfolgreich löschen", { theme: "colored" });
          setInputValue({});
          getAllUsers();
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row: any) => {
        return row?.index;
      },
      width: "65px",
    },
    {
      name: "Name",
      selector: (row: any) => row?.fname + " " + row?.lname,
      sortable: true,
    },
    {
      name: "Rolle",
      selector: (row: any) =>
        row?.position === "Team Leader" ? "Teamleiter" : "Benutzer",
      sortable: true,
    },
    {
      name: "E-Mail",
      selector: (row: any) => row?.email,
      sortable: true,
    },
    {
      // name: "Contact no",
      name: "Telefonnummer",
      selector: (row: any) => row?.phone,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2 pr-2"
                onClick={() => {
                  setIsType(true);
                  console.log("rows", row);
                  setIsEditApi(true);
                  setIdForEditType(row?._id);
                  getAllUsers();
                  setInputValue({
                    fname: row?.fname,
                    lname: row?.lname,
                    email: row?.email,
                    phone: row?.phone,
                  });
                }}
              >
                <Tooltip title="Bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
            </div>

            <div
              className="cursor-pointer"
              onClick={(e) => {
                console.log("delete", e.target);
                setIdForDelete(row?._id);
                setShow(true);
              }}
            >
              <Tooltip title="Erinnerung löschen" arrow>
                <DeleteIcon />
              </Tooltip>
            </div>

            {row?.position === "User" ?
              (<>
                <div className="d-flex justify-content-between">
                  <div
                    className="cursor-pointer pl-2"
                    onClick={() => {
                      console.log("Progress row", row);
                      // getShowlesson(row?.lessonView)
                      handleshowCourseModel(row);
                      getShowCourses(row?._id);
                    }}
                  >
                    <Tooltip title="Zeigen Module" arrow>
                      <InfoOutlined />
                    </Tooltip>
                  </div>
                </div>
              </>) :
              (<>
                <div className="d-flex justify-content-between">
                  <div
                    className="cursor-pointer pl-2"
                    onClick={() => {
                      console.log("UserRow---->", row);
                      // getShowlesson(row?.lessonView)
                      handleUserShowModal(row);
                      getShowUser(row?._id);
                    }}
                  >
                    <Tooltip title="Benutzer anzeigen" arrow>
                      <InfoOutlined />
                    </Tooltip>
                  </div>
                </div>
              </>)}
          </>
        );
      },
    },
    {
      name: "To Do Board",
      cell: (row: any) => {
        return (
          <>
            {row?.position === "Team Leader" ?
              (<div className="pl-16">-</div>)
              : (<>
                <div className="d-flex justify-content-between">
                  <div
                    className="cursor-pointer pl-2 pr-2"
                    onClick={() => {
                      console.log("object", row);
                      history.push(`/board/${row?._id}`);
                    }}
                  >
                    <button className="btn btn-warning">To Do Board</button>
                  </div>
                </div>
              </>
              )}
          </>
        );
      },
    },
  ];

  const columns1 = [
    {
      name: "SNr.",
      cell: (row: any) => {
        return row?.index;
      },
    },
    {
      name: "Titel",
      selector: (row: any) => row?.name,
      sortable: true,
    },
    {
      // name: "Description",
      name: "Beschreibung",
      selector: (row: any) => row?.description,
      sortable: true,
    },
    {
      // name: "Duration",
      name: "Dauer",
      selector: (row: any) => row?.duration,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  console.log("Progress row", row);
                  console.log(" row?.lessonView?.name", row?.lessonView?.name);

                  // setAdminid(row?._id);
                  handleshowlessonModel(row);
                  // getShowlesson(row?._id);
                }}
              >
                <Tooltip title="Zeigen Lektion" arrow>
                  <InfoOutlined />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const columns2 = [
    {
      name: "SNr.",
      cell: (row: any, index: any) =>
        index + 1
    },
    {
      name: "Lektion Titel",
      // selector: (row: any) => row?.lessonView?.map((data:any)=>{return data?.name}),
      selector: (row: any) => row?.name,
      sortable: true,
    },
    {
      name: "Beschreibung",
      selector: (row: any) => row?.description,
      sortable: true,
    },
  ];

  {/*column for TL's users */ }
  const columns3 = [
    {
      name: "SNr.",
      cell: (row: any) => {
        return row?.index;
      },
    },
    {
      name: "Name",
      selector: (row: any) => row?.fname + " " + row?.lname,
      sortable: true,
    },
    {
      name: "E-Mail",
      selector: (row: any) => row?.email,
      sortable: true,
    },
    {
      name: "Telefonnummer",
      selector: (row: any) => row?.phone,
      sortable: true,
    },
    // {
    //   name: "To Do Board",
    //   cell: (row: any) => {
    //     return (
    //       <>
    //             <div className="d-flex justify-content-between">
    //           <div
    //             className="cursor-pointer pl-2 pr-2"
    //             onClick={() => {
    //               history.push(`/board/${row?._id}`);
    //             }}
    //           >
    //             <button className="btn btn-warning">To Do Board</button>
    //           </div>
    //         </div>

    //       </>
    //     );
    //   },
    // },
  ];


  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Users</h2> */}
              <h2 className="pl-3 pt-2">Benutzer</h2>

            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="title"
                  placeholder="Benutzer suchen"
                  onChange={(e) => handleSearchData(e)}
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-warning mr-2"

                // style={{backgroundColor: "#F39200"}}
                onClick={() => {
                  setIsType(true);
                  // getAllCategory();
                }}
              >
                {/* Add User */}Nutzer hinzufügen
              </button>
            </div>
          </div>

          {/* delete model */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Warnung!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Möchten Sie dies sicher löschen Benutzer?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {/* cancel */}Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  Deletuser();
                }}
              >
                {/* Delete */}Löschen
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end delete model */}

          <DataTable
            columns={columns}
            data={userData}
            progressPending={loading}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
            pagination
          />
        </div>
      </div>

      {isType ? (
        <Dialog
          fullScreen
          open={isType}
          onClose={handleAddInvoice}
        // TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleAddInvoice}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isType === true ? (
              <div className="form ml-30 ">
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* First name */}Vorname
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="fname"
                        name="fname"
                        value={inputValue?.fname}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["fname"]}
                    </span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Last name */}Nachname
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="lname"
                        name="lname"
                        value={inputValue?.lname}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["lname"]}
                    </span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    E-Mail
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="email"
                        className={`form-control form-control-lg form-control-solid `}
                        id="email"
                        name="email"
                        value={inputValue?.email}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Contact number */}
                    Telefonnummer
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="number"
                        className={`form-control form-control-lg form-control-solid `}
                        id="phone"
                        name="phone"
                        value={inputValue?.phone}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["phone"]}
                    </span>
                  </div>
                </div>

                {!isEditApi && userInfo?.position === "Admin" && (
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      {/* Contact number */}
                      Rolle
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <select
                          className={`form-control form-control-lg form-control-solid `}
                          id="position"
                          name="position"
                          value={inputValue?.position}
                          onChange={(e) => {
                            handleOnChange(e);
                          }}
                        >
                          <option value="" disabled selected hidden>
                            Position auswählen
                          </option>
                          <option value="User">Benutzer</option>
                          <option value="Team Leader">Teamleiter</option>
                        </select>
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["position"]}
                      </span>
                    </div>
                  </div>
                )}

                {!isEditApi && (<>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Passwort
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <input
                          type="password"
                          className={`form-control form-control-lg form-control-solid `}
                          id="password"
                          name="password"
                          value={inputValue?.password}
                          onChange={(e) => {
                            handleOnChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["password"]}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Möchten Sie diesen Benutzer einem Teamleiter zuweisen?
                    </label>
                    <div className=""> <input type="radio" name="radio" value="true" className="ml-3 mr-3 mt-3" onChange={handleOnChange} /><label className="mr-20">Ja</label></div>
                    <div className=""><input type="radio" name="radio" value="false" className="ml-3 mr-3 mt-3" onChange={handleOnChange} /><label className="mr-20">Nein</label></div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                        marginTop: "20px"
                      }}
                    >
                      {errors["radio"]}
                    </span>
                  </div>

                  {assignedUser === "true" &&
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        {/* Assign team leader */}
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <div>
                          <select
                            className={`form-control form-control-lg form-control-solid `}
                            id="tlid"
                            name="tlid"
                            value={inputValue.tlid}
                            onChange={(e) => {
                              handleOnChange(e);
                            }}
                          >
                            <option value="" disabled selected hidden>
                              Teamleiter auswählen
                            </option>

                            {onlyTL &&
                              onlyTL?.map((item: any) => {
                                return (
                                  <option value={item?._id}>{item?.fname + " " + item?.lname + " (" + item?.email + ")"}</option>
                                );
                              })}
                          </select>
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["tlid"]}
                        </span>
                      </div>
                    </div>}</>
                )}

                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-warning mr-2">
                      {/* <span>{isEditApi ? "Edit" : "Add"} User</span> */}
                      <span>Speichern</span>

                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning mr-2"
                      onClick={(e) => {
                        isEditApi ? editUser(e) : addUser(e);
                      }}
                    >
                      {/* <span>{isEditApi ? "Edit User" : "Add User"}</span> */}
                      <span>Speichern</span>

                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </List>
          <List></List>
        </Dialog>
      ) : null}

      {Showmodel ? (
        <Dialog fullScreen open={Showmodel} onClose={handleClose}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {Showmodel === true ? (
              <div className="card p-1">
                <div className="p-2 mb-2">
                  <div className="d-flex justify-content-between mb-2">
                    <div className="col-md-3 col-sm-12 d-flex justify-content-between">
                      <h2 className="pl-3 pt-2">Geöffnet Module</h2>
                      {/* <h2 className="pl-3 pt-2">Opened Courses</h2> */}
                    </div>
                  </div>

                  <DataTable
                    columns={columns1}
                    data={progressCourseData}
                    customStyles={customStyles}
                    style={{
                      marginTop: "-3rem",
                    }}
                    progressPending={loading}
                    progressComponent={
                      <Loader
                        type="Puff"
                        color="#334D52"
                        height={30}
                        width={30}
                      />
                    }
                    highlightOnHover
                    pagination
                  />
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}

      {/* show more--->show user moal open */}
      {userModal ? (
        <Dialog fullScreen open={userModal} onClose={handleClose}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {userModal === true ? (
              <div className="card p-1">
                <div className="p-2 mb-2">
                  <div className="d-flex justify-content-between mb-2">
                    <div className="col-md-3 col-sm-12 d-flex justify-content-between">
                      <h2 className="pl-3 pt-2">
                        Benutzer
                      </h2>
                    </div>
                  </div>

                  <DataTable
                    columns={columns3}
                    data={modalUser}
                    customStyles={customStyles}
                    style={{
                      marginTop: "-3rem",
                    }}
                    progressPending={loading}
                    progressComponent={
                      <Loader
                        type="Puff"
                        color="#334D52"
                        height={30}
                        width={30}
                      />
                    }
                    highlightOnHover
                    pagination
                  />
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}
      {/* show more--->show user moal close */}

      {Showmodellesson ? (
        <Dialog fullScreen open={Showmodellesson} onClose={handleClose}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {Showmodellesson === true ? (
              <div className="card p-1">
                <div className="p-2 mb-2">
                  <div className="d-flex justify-content-between mb-2">
                    <div className="col-md-3 col-sm-12 d-flex justify-content-between">
                      {/* <h2 className="pl-3 pt-2">Opened Lessons</h2> */}
                      <h2 className="pl-3 pt-2">Geöffnet Lektionen</h2>
                    </div>
                  </div>

                  <DataTable
                    columns={columns2}
                    data={progressLessonData}
                    customStyles={customStyles}
                    style={{
                      marginTop: "-3rem",
                    }}
                    progressPending={loading}
                    progressComponent={
                      <Loader
                        type="Puff"
                        color="#334D52"
                        height={30}
                        width={30}
                      />
                    }
                    highlightOnHover
                    pagination
                  />
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}

    </>
  );
};

export default AllUsers;
