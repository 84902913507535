import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const BordName = () => {
  let userInfo = getUserInfo();

  const [isType, setIsType] = useState<any>();
  const [inputValue, setInputValue] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [inputValueForAdd, setInputValueForAdd] = useState<any>();
  const [errorsForAdd, setErrorsForAdd] = useState<any>({});
  const [isEditApi, setIsEditApi] = useState<any>(false);
  const [idForEditType, setIdForEditType] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [show, setShow] = useState<any>(false);
  const [IdforDelete, setIdForDelete] = useState();

  const Validation = () => {
    let error: any = {};
    let Formvalid = true;
    if (!inputValue.name) {
      Formvalid = false;
      error["name"] = "Board Kategorie ist nötig"
    }
    setErrors(error);
    return Formvalid;
  }

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "name" && value.charAt(e.length) == " ") return;
    else {
      setInputValue({ ...inputValue, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
    console.log("inputValue", inputValue);
  };

  const handleAddInvoice = () => {
    setIsType(false);
    setIsEditApi(false);
    setErrors({});
    setInputValue({});
  };

  const handleClose = () => {
    setShow(false);
  }

  useEffect(() => {
    getAllCategory();
  }, []);

  useEffect(() => {
    if(userInfo?.position==="Team Leader")
    {
        document.title =" Akademie | Teamleiter"; 
    }
    else {
      document.title = " Akademie | Admin";
    }

  }, []);

  const getAllCategory = async () => {
    setLoading(true)
    if (userInfo?.position === "Admin") {
      await ApiGet(`boardName/get-all-boardName?aid=${userInfo?._id}`)
        .then((res: any) => {
          setLoading(false)
          if (res?.data?.result === 0) {
            console.log("get Board Name", res?.data?.payload?.boardName);
            res.data.payload.boardName.map((e: any, index: any) => {
              e.index = index + 1;
              return e;
            });
            setUserData(res?.data?.payload?.boardName);
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log("err", err?.response);
          toast.error(err?.response?.data?.message, { theme: "colored" });

        });
    }
    if (userInfo?.position === "Team Leader") {
      console.log("enter in TL")
      await ApiGet(`boardName/get-all-boardName?tlid=${userInfo?._id}`)
        .then((res: any) => {
          setLoading(false)
          if (res?.data?.result === 0) {
            console.log("get Board Name", res?.data?.payload?.boardName);
            res.data.payload.boardName.map((e: any, index: any) => {
              e.index = index + 1;
              return e;
            });
            setUserData(res?.data?.payload?.boardName);
          }
        })
        .catch((err) => {
          console.log("err", err?.response);
          setLoading(false)
          toast.error(err?.response?.data?.message, { theme: "colored" });
        });
    }

  };

  console.log("boarddata", userData)

  const AddCategory = async (e: any) => {
    if (userInfo?.position === "Team Leader") {
      inputValue.aid = userInfo?.aid;
      inputValue.tlid = userInfo?._id
    } else {
      inputValue.aid = userInfo?._id
    }

    if (Validation()) {
      setLoading(true)
      await ApiPost(`boardName/add-boardName`, inputValue)
        .then((res: any) => {
          setLoading(false)
          if (res?.status === 200) {
            toast.success("Daten erfolgreich hinzugefügt", { theme: "colored" });
            console.log("add type", res);
            setInputValue({});
            setIsType(false);
            getAllCategory();
            setInputValueForAdd({});
          } else {
            toast.error(res?.data?.message, { theme: "colored" });
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log("err", err);
          toast.error(err?.response?.data?.message, { theme: "colored" });
        });
    }

  };

  const editCategory = async (e: any) => {
    setLoading(true)
    await ApiPut(`boardName/update-boardName/${idForEditType}`, inputValue)
      .then((res: any) => {
        setLoading(false)
        if (res?.status === 200) {
          toast.success("Daten erfolgreich aktualisiert", { theme: "colored" });
          console.log("editcatogory", res);
          setIsType(false);
          getAllCategory();
          setInputValue({});
          setInputValueForAdd({});
          setIsEditApi(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const DeleteCategory = async () => {
    await ApiDelete(`boardName/delete-boardName/${IdforDelete}`)
      .then((res: any) => {
        console.log("status res", res);
        if (res?.status === 200) {
          setShow(false);
          toast.success("Daten erfolgreich löschen", { theme: "colored" });
          setInputValue({});                                                                                                                                                                
          getAllCategory();
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setShow(false)
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      })
  };

  const handleDragEnd1 = async (e: any) => {
    if (!e.destination) return;
    console.log("eeeeeee", e);

    let tempData = Array.from(userData);
    console.log("bbbbbbb", tempData);

    let [source_data] = tempData.splice(e.source.index, 1);
    console.log("ccccccccccc", e.source.index);
    tempData.splice(e.destination.index, 0, source_data);
    console.log("dddddddd", e.destination.index);

    // setUsers1(userData);

    console.log(" e.source.draggableId1111", e.draggableId.split(",")[0]);
    let Data = {
      aid: userInfo?._id,
      oldPosition: e.source.index + 1,
      newPosition: e.destination.index + 1,
    };

    setLoading(true);
    await ApiPut(`boardName/update-boardNumber/${e.draggableId.split(",")[0]}`, Data)
      .then((res: any) => {
        setLoading(false);
        if (res?.status === 200) {
          toast.success("Daten erfolgreich bearbeiten", { theme: "colored" });
          console.log("editAdmin", res);

          getAllCategory();
          setInputValue({});
          setInputValueForAdd({});
          setIsEditApi(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row: any) => {
        return row?.index;
      },
      width: "65px",
    },
    {
      // name: "Board Name",
      name: "Board Kategorie",
      selector: (row: any) => row?.name,
      sortable: true,
    },

    {
      name: "Einstellungen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2 pr-2"
                onClick={() => {
                  setIsType(true);
                  console.log("rows", row);
                  setIsEditApi(true);
                  setIdForEditType(row?._id);
                  getAllCategory();
                  setInputValue({
                    name: row?.name,
                  });
                }}
              >
                <Tooltip title="Bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
              <div
                className="cursor-pointer pl-2 pr-2"
                onClick={(e) => {
                  console.log("delete catagory", e.target);
                  setIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="Erinnerung löschen" arrow>
                <DeleteIcon />
              </Tooltip>
              </div>
            </div>
          </>
        );
      }
    },

  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">To Do Board</h2>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-warning mr-2"
                onClick={() => {
                  setIsType(true);
                  // getAllCategory();
                }}
              >
                Hinzufügen Board Kategorie
              </button>
            </div>
          </div>

          {/* delete model */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Warnung!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Are You Sure To Want To delete this Board Name? */}
              Möchten Sie dies sicher löschen, um den Namen zu machen?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  DeleteCategory();
                }}
              >
                Löschen
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end delete model */}

          {userData?.length !== 0 ? (
            <DragDropContext onDragEnd={handleDragEnd1}>
              <table className="table borderd">
                <thead>
                  <tr>
                    <th />
                    <th>Board Kategorie</th>
                    {/* <th>Status</th> */}
                    <th>Einstellungen</th>
                  </tr>
                </thead>
                <Droppable droppableId="droppable-1">
                  {(provider) => (
                    <tbody
                      className="text-capitalize"
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                    >
                      {userData?.map((row: any, index: any) => (
                        <Draggable
                          key={row.name}
                          draggableId={row?._id}
                          index={index}
                        >
                          {(provider) => (
                            <tr
                              {...provider.draggableProps}
                              ref={provider.innerRef}
                            >
                              <td {...provider.dragHandleProps}> = </td>
                              <td>{row?.name}</td>
                              {/* <td>
                                <>
                                  {
                                    <>
                                      <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setShow(true)
                                        }}
                                      >
                                        <Tooltip title="Modul deaktivieren" arrow>
                                          <span className="badge badge-secondary w-100">
                                            {row?.isActive === true ? "Aktiv" : "Deaktiv"}
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </>
                                  }
                                </>
                              </td> */}
                              <td>
                                <>
                                  {
                                    <>
                                    <div className="d-flex">
                                      <div className="d-flex justify-content-between">
                                        <div
                                          className="cursor-pointer pl-2 pr-2"
                                          onClick={() => {
                                            setIsType(true);
                                            console.log("rows", row);
                                            setIsEditApi(true);
                                            setIdForEditType(row?._id);
                                            getAllCategory();
                                            setInputValue({
                                              name: row?.name,
                                              description: row?.description
                                            });
                                          }}
                                        >
                                          <Tooltip title="Bearbeiten" arrow>
                                            <CreateIcon />
                                          </Tooltip>
                                        </div>
                                      </div>

                                      <div className="d-flex justify-content-between">
                                        <div
                                          className="cursor-pointer pl-2 pr-2"
                                          onClick={(e) => {
                                            console.log("delete row",row);
                                            setIdForDelete(row?._id);
                                            setShow(true);
                                          }}
                                        >
                                          <Tooltip title="Erinnerung löschen" arrow>
                                          <DeleteIcon />
                                        </Tooltip>
                                        </div>
                                      </div>
                                      </div>
                                    </>
                                  }
                                </>
                              </td>

                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provider.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
          ) : (
            <h4 style={{ textAlign: "center" }}>Keine Daten gefunden</h4>
          )}

          {/* <DataTable columns={columns} data={userData}
            progressPending={loading}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
            pagination /> */}
        </div>
      </div>

      {isType ? (
        <Dialog
          fullScreen
          open={isType}
          onClose={handleAddInvoice}
        // TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleAddInvoice}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isType === true ? (
              <div className="form ml-30 ">
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Board Kategorie
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Board Name"
                        className={`form-control form-control-lg form-control-solid `}
                        id="name"
                        name="name"
                        value={inputValue.name}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["name"]}
                    </span>
                  </div>
                </div>


                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-fillcolor mr-2">
                      {/* <span>{isEditApi ? "Edit" : "Add"} Board Name</span> */}

                      <span>{isEditApi ? "Bearbeiten" : "Hinzufügen"} Board Kategorie </span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  ) : (
                    <button
                      className="btn btn-fillcolor mr-2"
                      onClick={(e) => {
                        isEditApi ? editCategory(e) : AddCategory(e);
                      }}
                    >
                      {/* <span>{isEditApi ? "Edit Board Name" : "Add Board Name"}</span> */}
                      <span>{isEditApi ? "Bearbeiten Board Kategorie " : "Hinzufügen Board Kategorie "}</span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}
    </>
  );
};

export default BordName;
