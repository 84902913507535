import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import {
  ApiGet,
  ApiDelete,
  ApiPut,
  ApiPost,
} from "../../../helpers/API/ApiData";
import "../../../index.scss";
import { Tooltip } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { List } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Toolbar } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Slide } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useParams } from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Question = (props) => {
  const { id } = useParams();
  const [filteredCourseName, setFilteredCourseName] = useState({});
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAddCourseName, setIsAddCourseName] = useState(false);
  const [idForUpdateCourseNameData, setIdForUpdateCourseNameData] =
    useState("");
  const [inputValueForAdd, setInputValueForAdd] = useState({});
  const [errorsForAdd, setErrorsForAdd] = useState({});
  const [idForDeleteCourseName, setIdForDeleteCourseName] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [idForUpdateCourseStatus, setIdForUpdateCourseStatus] = useState("");
  const [statusDisplay, setStatusDisplay] = useState(false);

  const [dataViewMore, setDataViewMore] = useState({});
  const [isViewMoreAboutus, setIsViewMoreAboutus] = useState(false);
  const [isEditPopUp, setIsEditPopUp] = useState(false);

  useEffect(() => {
    // document.title = "Akademie | Question Addition";
    document.title = "Akademie | Fragenkatalog";
  }, []);

  const handleViewMoreClose = () => {
    setIsViewMoreAboutus(false);
    setDataViewMore({});
  };

  const handleOnChnageAdd = (e) => {
    const { name, value } = e.target;
    if (name === "weight") {
      setErrorsForAdd({ ...errorsForAdd, [name]: "" });
      return setInputValueForAdd({
        ...inputValueForAdd,
        [name]: parseInt(value),
      });
    }
    if (name === "vcid") {
      setErrorsForAdd({ ...errorsForAdd, [name]: "" });
      return setInputValueForAdd({
        ...inputValueForAdd,
        [name]: value,
        vehicleSubCategory: "",
        Category: "",
      });
    }
    if (name === "vehicleSubCategory") {
      setErrorsForAdd({ ...errorsForAdd, [name]: "" });
      return setInputValueForAdd({
        ...inputValueForAdd,
        [name]: value,
        Category: "",
      });
    }
    setInputValueForAdd({ ...inputValueForAdd, [name]: value });
    setErrorsForAdd({ ...errorsForAdd, [name]: "" });
  };

  const handleAddAdminClose = () => {
    setInputValueForAdd({});
    setIsAddCourseName(false);
    setErrorsForAdd({});
    setIsEditPopUp(false);

    setOption([]);
    setMcqCheck(false);
    setCheckBoxCheck(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseShowStatus = () => {
    setShowStatus(false);
  };

  useEffect(() => {
    getAllQuestionSet();
  }, [page, countPerPage]);

  const getAllQuestionSet = async () => {
    setIsLoaderVisible(true);
    if (!search) {
      await ApiGet(
        `question/get-all-question?course_id=${id}&search=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setIsLoaderVisible(false);
          setFilteredCourseName(res?.data?.payload?.question);
          setCount(res?.data?.payload?.count);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      await ApiGet(
        `question/get-all-question?course_id=${id}&search=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setIsLoaderVisible(false);
          setFilteredCourseName(res?.data?.payload?.question);
          setCount(res?.data?.payload?.count);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const validateFormForAddAdmin = () => {
    let formIsValid = true;
    let errorsForAdd = {};
    if (inputValueForAdd && !inputValueForAdd.name) {
      formIsValid = false;
      errorsForAdd["name"] = "*Please enter name!";
    }

    if (inputValueForAdd && !inputValueForAdd.type) {
      formIsValid = false;
      errorsForAdd["type"] = "*Please enter type!";
    }

    option.map((data, i) => {
      if (data.name === "") {
        formIsValid = false;
        return toast.error(`Enter Option ${i + 1}`);
      }
    });

    setErrorsForAdd(errorsForAdd);
    return formIsValid;
  };

  const handelAddCourseNameDetails = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateFormForAddAdmin()) {
      let Data = {
        qname: inputValueForAdd.name,
        course_id: id,
        type: inputValueForAdd.type,
        option: option,
      };
      let filter = option.filter((e) => e.istrue === true);
      console.log("filter", filter, filter.length);
      if (filter.length) {
        ApiPost(`question/add-question`, Data)
          .then((res) => {
            if (res?.status == 200) {
              setIsAddCourseName(false);
              toast.success(res?.data?.message);
              setInputValueForAdd({});
              getAllQuestionSet();
              setLoading(false);
            } else {
              toast.error(res?.data?.message);
              setLoading(false);
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
            setLoading(false);
          });
      } else {
        toast.error(`Please select any one ${Data.type}`);
        setLoading(false);
      }
    }
  };

  const handleDeleteCourseName = () => {
    ApiDelete(`question/delete-question/${idForDeleteCourseName}`)
      .then((res) => {
        if (res?.status === 200) {
          setShow(false);
          toast.success("Erfolgreich gelöscht");
          getAllQuestionSet();
          setPage(1);
          setCount(0);
          setCountPerPage(countPerPage);
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handelUpdateCourseNameDetails = (e) => {
    e.preventDefault();
    if (validateFormForAddAdmin()) {
      let Data = {
        qname: inputValueForAdd.name,

        type: inputValueForAdd.type,

        option: option,
        course_id: id,
      };
      let filter = option.filter((e) => e.istrue === true);

      if (filter.length) {
        ApiPut(`question/update-question/${idForUpdateCourseNameData}`, Data)
          .then((res) => {
            if (res?.status == 200) {
              setIsAddCourseName(false);
              toast.success(res?.data?.message);
              setInputValueForAdd({});
              getAllQuestionSet();
              setIsEditPopUp(false);
            } else {
              toast.error(res?.data?.message);
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      } else {
        toast.error(`Please select any one ${Data.type}`);
      }
    }
  };

  const handleUpdateStatusProperty = (status) => {
    ApiPut(`question/updateStatus/${idForUpdateCourseStatus}`, {
      isActive: status,
    })
      .then((res) => {
        if (res?.status == 200) {
          setShowStatus(false);
          getAllQuestionSet();
          toast.success("Status updated Successfully");
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },

    {
      // name: "Question Name",
      name: "Titel der Aufgabe",
      selector: "qname",
      sortable: true,
    },

    {
      // name: "Type",
      name: "Variante",
      selector: "type",
      sortable: true,
    },

    {
      name: "Einstellungen",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsAddCourseName(true);
                  if (row) {
                    if (row.type === "mcq") {
                      setMcqCheck(true);
                    } else if (row.type === "checkbox") {
                      setCheckBoxCheck(true);
                    }
                    setOption(row.option);
                  }
                  setIdForUpdateCourseNameData(row?._id);
                  setOption(row?.option);
                  setInputValueForAdd({
                    name: row?.qname,
                    type: row?.type,
                  });
                  if (row?.type === "mcq") {
                    setMcqCheck(true);
                    setCheckBoxCheck(false);
                  } else {
                    setMcqCheck(false);
                    setCheckBoxCheck(true);
                  }

                  setIsEditPopUp(true);
                }}
              >
                <Tooltip title="Frage bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
            </div>

            <div
              className="cursor-pointer"
              onClick={() => {
                setShow(true);
                setIdForDeleteCourseName(row?._id);
              }}
            >
              <Tooltip title="Frage löschen" arrow>
                <DeleteIcon />
              </Tooltip>
            </div>
            <>
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsViewMoreAboutus(true);

                  setDataViewMore(row);
                }}
              >
                <Tooltip title="Zeig mehr" arrow>
                  <InfoOutlinedIcon />
                </Tooltip>
              </div>
            </>
          </>
        );
      },
    },

    // {
    //   name: "Display?",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <div
    //           className="cursor-pointer"
    //           onClick={() => {
    //             setShowStatus(true);
    //             setIdForUpdateCourseStatus(row?._id);
    //             setStatusDisplay(row?.isActive);
    //           }}
    //         >
    //           <Tooltip title="Status Property" arrow>
    //             <div className="cus-medium-button-style widthfixed">
    //               <button className="btn btn-success mr-2">
    //                 {row?.isActive === true ? "Aktiv" : "Deaktivieren"}
    //               </button>
    //             </div>
    //           </Tooltip>
    //         </div>
    //       </>
    //     );
    //   },
    //   sortable: true,
    // },
  ];
  // * Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  //for search data

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  // Hook
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsLoaderVisible(true);
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllQuestionSet();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllQuestionSet();
    }
  }, [debouncedSearchTerm]);

  //for excel file
  const [allCourseNameExcel, setAllCourseNameExcel] = useState([]);
  const [dataCSV, setDataCSV] = useState([]);

  useEffect(() => {
    if (allCourseNameExcel) {
      allCourseNameExcel.map((registerUser, key) => {
        let data = {
          Number: key + 1,
          CreatedAt: moment(registerUser?.createdAt).format("ll"),
          QuestionName: registerUser?.Qname,
          language: registerUser?.language,
          type: registerUser?.type,
          // weight: registerUser?.weight,
          Category: registerUser?.Category?.name,
          image: registerUser?.image,
        };
        setDataCSV((currVal) => [...currVal, data]);
      });
    }
  }, [allCourseNameExcel]);

  const [mcqCheck, setMcqCheck] = useState(false);
  const [checkBoxCheck, setCheckBoxCheck] = useState(false);
  function typeChanges(e) {
    if (e.target.value === "mcq") {
      setMcqCheck(true);
      setCheckBoxCheck(false);
    } else {
      setCheckBoxCheck(true);
      setMcqCheck(false);
    }
  }

  const [option, setOption] = useState([
    {
      no: "1",
      name: "",
      istrue: false,
    },
    {
      no: "2",
      name: "",
      istrue: false,
    },
    {
      no: "3",
      name: "",
      istrue: false,
    },
    {
      no: "4",
      name: "",
      istrue: false,
    },
  ]);
  const handleQuestion = (data, index) => {
    let list = [...option];
    let i = index;
    let type = data.target.type;
    let value;
    if (type === "radio") {
      value = data.target.value === "on" ? true : false;
    } else {
      value = data.target.value;
    }
    if (i === 0) {
      if (type === "radio") {
        list[i].istrue = value;
        list.map((item, index) => {
          if (index !== i) {
            list[index].istrue = !value;
          }
        });
      } else if (type === "text") {
        list[index].name = value;
      }
    } else if (i === 1) {
      if (type === "radio") {
        list[i].istrue = value;
        list.map((item, index) => {
          if (index !== i) {
            list[index].istrue = !value;
          }
        });
      } else if (type === "text") {
        list[i].name = value;
      }
    } else if (i === 2) {
      if (type === "radio") {
        list[i].istrue = value;
        list.map((item, index) => {
          if (index !== i) {
            list[index].istrue = !value;
          }
        });
      } else if (type === "text") {
        list[i].name = value;
      }
    } else if (i === 3) {
      if (type === "radio") {
        list[i].istrue = value;
        list.map((item, index) => {
          if (index !== i) {
            list[index].istrue = !value;
          }
        });
      } else if (type === "text") {
        list[i].name = value;
      }
    } else {
      setOption(option);
    }
    setOption(list);
  };
  const handleQuestionCheckBox = (data, index) => {
    let list = [...option];
    let i = index + 1;
    let type = data.target.type;

    let value;
    if (type === "checkbox") {
      value = data.target.value === "on" ? true : false;
    } else {
      value = data.target.value;
    }

    if (i === 1) {
      if (type === "checkbox") {
        list[i - 1].istrue = !list[i - 1].istrue;
      } else if (type === "text") {
        list[i - 1].name = value;
      }
    } else if (i === 2) {
      if (type === "checkbox") {
        list[i - 1].istrue = !list[i - 1].istrue;
      } else if (type === "text") {
        list[i - 1].name = value;
      }
    } else if (i === 3) {
      if (type === "checkbox") {
        list[i - 1].istrue = !list[i - 1].istrue;
      } else if (type === "text") {
        list[i - 1].name = value;
      }
    } else if (i === 4) {
      if (type === "checkbox") {
        list[i - 1].istrue = !list[i - 1].istrue;
      } else if (type === "text") {
        list[i - 1].name = value;
      }
    } else {
      setOption(option);
    }
    setOption(list);
  };

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Question Addition </h2> */}
              <h2 className="pl-3 pt-2">Fragenkatalog </h2>
            </div>
            {/* <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="search"
                  value={search}
                  placeholder="Suchfrage"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div> */}
            <div className="cus-medium-button-style button-height">
              <button
                onClick={() => {
                  setIsAddCourseName(true);
                  setOption([
                    {
                      no: "1",
                      name: "",
                      istrue: false,
                    },
                    {
                      no: "2",
                      name: "",
                      istrue: false,
                    },
                    {
                      no: "3",
                      name: "",
                      istrue: false,
                    },
                    {
                      no: "4",
                      name: "",
                      istrue: false,
                    },
                  ]);
                }}
                className="btn btn-fillcolor mr-2"
              >
                {/* Add Question */}
                Aufgabe hinzufügen
                {loading && (
                  <span className="mx-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </div>

          {/* delete model */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Warnung!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Möchten Sie diese Question sicher löschen?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleDeleteCourseName();
                }}
              >
                Löschen
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end delete model */}

          <DataTable
            columns={columns}
            data={filteredCourseName}
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        </div>
      </div>

      {isAddCourseName ? (
        <Dialog
          fullScreen
          open={isAddCourseName}
          onClose={handleAddAdminClose}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleAddAdminClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAddCourseName === true ? (
              <div className="form ml-30 ">
                {/* Name Amenintie */}
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Fragen Titel
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="name"
                        name="name"
                        value={inputValueForAdd.name}
                        onChange={(e) => {
                          handleOnChnageAdd(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errorsForAdd["name"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Select type */}
                    Select variante
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <select
                        name="type"
                        type="text"
                        className={`form-control form-control-lg form-control-solid`}
                        value={inputValueForAdd.type}
                        onChange={(e) => {
                          handleOnChnageAdd(e);
                          typeChanges(e);
                        }}
                      >
                        <option selected disabled value="">
                          {/* Type */}
                          Variante
                        </option>
                        <option value="mcq">MCQ</option>
                        <option value="checkbox">Check box</option>
                      </select>
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errorsForAdd["type"]}
                    </span>
                  </div>
                </div>
                <div>
                  {mcqCheck && (
                    <>
                      <div
                        className="alert alert-msg"
                        style={{
                          margin: "auto",
                          marginBottom: "20px",
                          width: "300px",
                        }}
                      >
                       Hinweis: Bitte wählen Sie die richtige Antwort aus
                      </div>

                      {isAddCourseName
                        ? option?.map((data, index) => {
                            return (
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label"></label>
                                <div className="col-lg-9 col-xl-6">
                                  <div
                                    className="form-group d-flex align-items-center"
                                    key={index}
                                  >
                                    <input
                                      className="mr-3"
                                      type="radio"
                                      name="radio"
                                      id="radio"
                                      defaultChecked={data.istrue}
                                      onChange={(e) => handleQuestion(e, index)}
                                    />
                                    <input
                                      className="form-control"
                                      type="text"
                                      value={data.name}
                                      onChange={(e) => handleQuestion(e, index)}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : [0, 1, 2, 3].map((data, index) => {
                            return (
                              <div
                                className="form-group d-flex align-items-center"
                                key={index}
                              >
                                <input
                                  className="mr-3"
                                  type="radio"
                                  name="radio"
                                  id="radio"
                                  checked={data.istrue}
                                  // defaultChecked={data.istrue}
                                  onChange={(e) => handleQuestion(e, index)}
                                />
                                <input
                                  className="form-control"
                                  type="text"
                                  onChange={(e) => handleQuestion(e, index)}
                                  value={data.name}
                                  required
                                />
                              </div>
                            );
                          })}
                    </>
                  )}

                  {checkBoxCheck && (
                    <>
                      <div
                        className="alert alert-msg "
                        style={{
                          margin: "auto",
                          marginBottom: "20px",
                          width: "300px",
                        }}
                      >
                        Note: Please select atleast one correct answer
                      </div>

                      {isAddCourseName
                        ? option?.map((data, index) => {
                            return (
                              <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label"></label>
                                <div className="col-lg-9 col-xl-6">
                                  <div
                                    className="form-group d-flex align-items-center"
                                    key={index}
                                  >
                                    <input
                                      className="mr-3"
                                      type="checkbox"
                                      defaultChecked={data.istrue}
                                      onChange={(e) =>
                                        handleQuestionCheckBox(e, index)
                                      }
                                    />
                                    <input
                                      className={`form-control form-control-lg form-control-solid`}
                                      type="text"
                                      value={data.name}
                                      onChange={(e) =>
                                        handleQuestionCheckBox(e, index)
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : [0, 1, 2, 3].map((data, index) => {
                            return (
                              <div
                                className="form-group d-flex align-items-center"
                                key={index}
                              >
                                <input
                                  className="mr-3"
                                  type="checkbox"
                                  defaultChecked={data.istrue}
                                  onChange={(e) =>
                                    handleQuestionCheckBox(e, index)
                                  }
                                />
                                <input
                                  className="form-control"
                                  type="text"
                                  value={data.name}
                                  onChange={(e) =>
                                    handleQuestionCheckBox(e, index)
                                  }
                                  required
                                />
                              </div>
                            );
                          })}
                    </>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    onClick={(e) => {
                      isEditPopUp === false
                        ? handelAddCourseNameDetails(e)
                        : handelUpdateCourseNameDetails(e);
                    }}
                    className="btn btn-fillcolor mr-2"
                  >
                    <span>
                      {" "}
                      {/* {isEditPopUp === false ? "Add" : "Edit"} Question{" "} */}
                      {isEditPopUp === false ? "Hinzufügen" : "Bearbeiten"} Frage
                    </span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}

      {isViewMoreAboutus ? (
        <Dialog
          fullScreen
          open={isViewMoreAboutus}
          onClose={handleViewMoreClose}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleViewMoreClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isViewMoreAboutus === true ? (
              <div className="honda-container">
                <div className="other-information-child-text-style1">
                  {/* <h2>Question Information</h2> */}
                  <h2>Frageninformationen</h2>
                </div>
                <div className="honda-text-grid12 honda-text-grid-border">
                  <div className="honda-text-grid-items">
                    <span>Name:</span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: dataViewMore?.qname,
                      }}
                      className=""
                    />
                  </div>

                  <div className="honda-text-grid-items">
                    {/* <span>Type:</span> */}
                    <span>Variante:</span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: dataViewMore?.type,
                      }}
                      className=""
                    />
                  </div>

                  <div className="honda-text-grid-items">
                    <span>Option:</span>
                    {dataViewMore?.option.map((data, key) => {
                      return (
                        <div className="d-flex">
                          {data?.istrue ? (
                            <>
                              <div className="mr-3 bold"> {data?.no}</div>
                              <div className="bold">{data?.name}</div>
                            </>
                          ) : (
                            <>
                              <div className="mr-3"> {data?.no}</div>
                              <div>{data?.name}</div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}

      <Modal show={showStatus} onHide={handleCloseShowStatus}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Warnung!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Willst du sicher wollen?{" "}
          {statusDisplay === true ? "Deaktivieren" : "Aktiv"} Dies Question
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseShowStatus}>
            Abbrechen
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              handleUpdateStatusProperty(!statusDisplay);
            }}
          >
            {statusDisplay === true ? "Deaktivieren" : "Aktiv"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Question;
