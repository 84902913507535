import React, { useState, useEffect, useRef } from "react"; //
import { useHistory } from "react-router-dom";
import { ApiPost, ApiPostNoAuth } from "../../../../helpers/API/ApiData";
import * as authUtil from "../../../../utils/auth.util";
import * as userUtil from "../../../../utils/user.util";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../../_metronic/_assets/sass/layout/_basic.scss";

export default function Login() {
  // const [loading, setLoading] = useState(false);
  const history = useHistory<any>();
  const [loginData, setLoginData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loader, setLoader] = useState<any>(false);
  const regexEmail =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

  const handleChange = (e: any) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = async (e: any) => {
    // setLoader(true);
    e.preventDefault();

    if (!loginData.email && !loginData.password) {
      setErrors({
        email: "E-Mail is required*",
        password: "Passwort is required*",
      });
    } else if (loginData.email === "" && loginData.password === "") {
      setErrors({ ...errors, email: "E-Mail ist nötig*" });
    } else if (!loginData.email || loginData.email === "") {
      setErrors({ ...errors, email: "E-Mail ist nötig*" });
    } else if (!loginData.email || regexEmail.test(loginData.email) === false) {
      setErrors({ ...errors, email: "E-Mail is not valid*" });
    } else if (!loginData.password || loginData.password === "") {
      setErrors({ ...errors, password: "Passwort ist nötig*" });
    } else {
      loginData.email = loginData.email.toLowerCase();
      await ApiPostNoAuth("admin/login", loginData)
        .then((res: any) => {
          console.log("login res", res?.data?.payload);

          if (res?.data?.result === 0) {
            if (res?.data?.payload?.admin?.position === "Super Admin" || res?.data?.payload?.admin?.position === "Admin"  || res?.data?.payload?.admin?.position === "Team Leader") {
              authUtil.setToken(res?.data?.payload?.token);
              userUtil.setUserInfo(res?.data?.payload?.admin);
              history.push("/");
              window.location.reload();
              setTimeout(function () {
                toast.success("Anmeldung erfolgreich", { theme: "colored" });
              }, 3000);
            }
            else {
              setErrors({ user: "Konnte nicht einloggen." })
            }
          } else {
            setErrors({ user: res?.data?.message })
          }

        })
        .catch((err) => {
          console.log("err----->", err);
          toast.error(err?.response?.data?.message, { theme: "colored" })
        });
    }
    // setLoader(false);
  };

  const handleForgotPass = (e: any) => {
    console.log("forgot");
    history.push("/auth/forgot-password");
  }


  function useKey(key: any, cb: any) {
    const callback = useRef(cb);

    useEffect(() => {
      callback.current = cb;
    });

    useEffect(() => {
      function handle(event: any) {
        if (event.code === key) {
          callback.current(event);
        }
      }

      document.addEventListener("keypress", handle);
      return () => document.removeEventListener("keypress", handle);
    }, [key]);
  }
  useKey("Enter", handleSubmit);

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Admin Dashboard</h3>
        <p className="text-muted font-weight-bold">
         Geben Sie Ihre Anmeldeinformationen ein.
        </p>
        <span className="text-danger h6">{errors.user}</span>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />

      <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="E-Mail"
          type="email"
          className={`form-control form-control-solid h-auto py-5 px-6  `}
          name="email"
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <span className="text-danger">{errors.email}</span>
      </div>
      <div className="form-group fv-plugins-icon-container">
        <input
          placeholder="Passwort"
          type="password"
          className={`form-control form-control-solid h-auto py-5 px-6 `}
          name="password"
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <span className="text-danger">{errors.password}</span>
      </div>
      {/* <div className="form-group fv-plugins-icon-container">
        <span onClick={(e)=>handleForgotPass(e)}>Forgot Password!</span>
      </div> */}
      <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
        <button
          id="kt_login_signin_submit"
          type="submit"
          className={`align-items-center d-flex btn btn-warning font-weight-bold px-9 py-4 my-3`}
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          {/* <span className="pr-2 ">Log In</span> */}
          <span className="pr-2 ">Einloggen</span>

          {/* {loader && (
            <div class="spinner-grow text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          )} */}
        </button>
      </div>
      {/* <div className="d-flex justify-content-center">
          <span className="font-weight-bold text-dark-50">
            Don't have an account yet?
          </span>
          <Link
            to="/auth/registration"
            className="font-weight-bold ml-2"
            id="kt_login_signup"
          >
            Sign Up!
          </Link>
        </div> */}
      {/* <div className="d-flex justify-content-center">
          <span className="font-weight-bold text-dark-50">
            Read our <Link>Onboarding Policy</Link> here.
          </span>
        </div> */}
      {/* </form> */}

      {/*end::Form*/}
    </div>
  );
}
