import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import {
  ApiGet,
  ApiDelete,
  ApiPut,
  ApiPost,
} from "../../../helpers/API/ApiData";
import { Tooltip } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { List } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Toolbar } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Slide } from "@material-ui/core";
import { Button } from "react-bootstrap";
import Select from "react-select";

import { Modal } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { getUserInfo } from "../../../utils/user.util";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const QuestionSet = (props) => {
  const history = useHistory();

  let userInfo = getUserInfo();
  const { id } = useParams();
  const [filteredCourseName, setFilteredCourseName] = useState({});
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAddCourseName, setIsAddCourseName] = useState(false);
  const [idForUpdateCourseNameData, setIdForUpdateCourseNameData] =
    useState("");
  const [inputValueForAdd, setInputValueForAdd] = useState ({});
  const [errorsForAdd, setErrorsForAdd] = useState({});
  const [idForDeleteCourseName, setIdForDeleteCourseName] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [names, setNames] = useState();

  const [countPerPage, setCountPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [idForUpdateCourseStatus, setIdForUpdateCourseStatus] = useState("");
  const [statusDisplay, setStatusDisplay] = useState(false);
  const [selectedNames, setSelectedNames] = useState([]);

  const [dataViewMore, setDataViewMore] = useState({});
  const [isViewMoreAboutus, setIsViewMoreAboutus] = useState(false);
  const [isEditPopUp, setIsEditPopUp] = useState(false);

  useEffect(() => {
    // document.title = "Academy | Question Set";
    document.title = "Academy | Quiz";
  }, []);
  useEffect(() => {
    getNames();
  }, []);

  const handleViewMoreClose = () => {
    setIsViewMoreAboutus(false);
    setDataViewMore({});
  };

  const handleOnChnageAdd = (e) => {
    const { name, value } = e.target;
    if (name === "weight") {
      setErrorsForAdd({ ...errorsForAdd, [name]: "" });
      return setInputValueForAdd({
        ...inputValueForAdd,
        [name]: parseInt(value),
      });
    }
    if (name === "vcid") {
      setErrorsForAdd({ ...errorsForAdd, [name]: "" });
      return setInputValueForAdd({
        ...inputValueForAdd,
        [name]: value,
        vehicleSubCategory: "",
        Category: "",
      });
    }
    if (name === "vehicleSubCategory") {
      setErrorsForAdd({ ...errorsForAdd, [name]: "" });
      return setInputValueForAdd({
        ...inputValueForAdd,
        [name]: value,
        Category: "",
      });
    }
    setInputValueForAdd({ ...inputValueForAdd, [name]: value });
    setErrorsForAdd({ ...errorsForAdd, [name]: "" });
  };

  const handleAddAdminClose = () => {
    setInputValueForAdd({});
    setIsAddCourseName(false);
    setErrorsForAdd({});
    setIsEditPopUp(false);

    setOption([]);
    setMcqCheck(false);
    setCheckBoxCheck(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseShowStatus = () => {
    setShowStatus(false);
  };

  useEffect(() => {
    getAllQuestionSet();
  }, [page, countPerPage]);

  const getAllQuestionSet = async () => {
    setLoading(true);
    setIsLoaderVisible(true);
    if (!search) {
      await ApiGet(`questionSet/getQuestionSet?cid=${id}`)
        .then((res) => {
          setLoading(false);
          setIsLoaderVisible(false);
          setFilteredCourseName(res?.data?.payload?.Questionset);
          setCount(res?.data?.payload?.count);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      await ApiGet(`questionSet/getQuestionSet?cid=${id}`)
        .then((res) => {
          // setLoading(false)
          // setIsLoaderVisible(false);
          setFilteredCourseName(res?.data?.payload?.Questionset);
          setCount(res?.data?.payload?.count);
          toast.success(res?.data?.message, { theme: "colored" });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const getNames = async () => {
    setLoading(true);
    // if(!search){
    await ApiGet(`admin/getUserListForBoard?&aid=${userInfo?._id}`)
      .then((res) => {
        setLoading(false);
        handleUserNames(res?.data?.payload?.findUsers);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleUserNames = (data) => {
    let tempUserName = [];
    data.map((item) =>
      tempUserName.push({ value: item._id, label: item.email })
    );
    setNames(tempUserName);
  };

  const validateFormForAddAdmin = () => {
    let formIsValid = true;
    let errorsForAdd = {};
    if (inputValueForAdd && !inputValueForAdd.name) {
      formIsValid = false;
      errorsForAdd["name"] = "*Bitte geben Sie den Namen ein!";
    }
    if (inputValueForAdd && !inputValueForAdd.description) {
      formIsValid = false;
      errorsForAdd["description"] = "*Bitte geben Sie Beschreibung ein!";
    }
    console.log("selectedNames?.length", selectedNames?.length);
    if (!isEditPopUp &&  selectedNames?.length === 0) {
      formIsValid = false;
      errorsForAdd["users"] = "*Bitte wählen Sie Benutzer aus!";
    }

    setErrorsForAdd(errorsForAdd);
    return formIsValid;
  };

  const handelAddCourseNameDetails = (e) => {
    e.preventDefault();

    if (validateFormForAddAdmin()) {
      let Data = {
        name: inputValueForAdd.name,
        description: inputValueForAdd.description,
        course_id: id,
        aid: userInfo?._id,
        users: selectedNames.map((item) => item.value),
      };
      setLoading(true);
      ApiPost(`questionset/addQuestionSet`, Data)
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            setIsAddCourseName(false);
            setLoading(false);
            toast.success(res?.data?.message);
            setInputValueForAdd({});
            setIsAddCourseName(false);
            getAllQuestionSet();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };
  const handleUsers = (e) => {
    console.log("aaaaaa", e);
    setSelectedNames(e);
    setErrorsForAdd("");
  };
  const handleDeleteCourseName = () => {
    ApiDelete(`questionset/deleteQuestionSet/${idForDeleteCourseName}`)
      .then((res) => {
        if (res?.status === 200) {
          setShow(false);
          toast.success("Erfolgreich gelöscht");
          getAllQuestionSet();
          setPage(1);
          setCount(0);
          setCountPerPage(countPerPage);
        } else {
          toast.error(res?.data?.message);
          setLoading(false)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handelUpdateCourseNameDetails = (e) => {
    e.preventDefault();
    if (validateFormForAddAdmin()) {
      let Data = {
        name: inputValueForAdd.name,
        description: inputValueForAdd.description,
       
      };
 setLoading(true)
      ApiPut(
        `questionset/updateQuestionSet/${idForUpdateCourseNameData}`,
        Data
      ).then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            setIsAddCourseName(false);
            toast.success(res?.data?.message);
            setInputValueForAdd({});
            getAllQuestionSet();
            setIsEditPopUp(false);
            setLoading(false)
          } else {
            toast.error(res?.data?.message);
            setLoading(false)
            setIsAddCourseName(false);
            setIsEditPopUp(false);

          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  const handleUpdateStatusProperty = (status) => {
    ApiPut(`question/updateStatus/${idForUpdateCourseStatus}`, {
      isActive: status,
    })
      .then((res) => {
        if (res?.status == 200) {
          setShowStatus(false);
          getAllQuestionSet();
          toast.success("Status erfolgreich aktualisiert");
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },

    {
      // name: "Question Set Name",
      name: "Titel",
      selector: "name",
      sortable: true,
    },

    {
      // name: "Question Set Description",
      name: "Beschreibung",
      selector: "description",
      sortable: true,
    },

    {
      name: "Einstellungen",
      cell: (row) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsAddCourseName(true);
                  if (row) {
                    if (row.type === "mcq") {
                      setMcqCheck(true);
                    } else if (row.type === "checkbox") {
                      setCheckBoxCheck(true);
                    }
                    setOption(row.option);
                  }
                  setIdForUpdateCourseNameData(row._id);
                  setOption(row?.option);
                  setInputValueForAdd({
                    name: row?.name,
                    description: row?.description,
                  });

                  setIsEditPopUp(true);
                }}
              >
                {/* <Tooltip title="Edit Question Set" arrow> */}
                <Tooltip title="Fragensatz bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShow(true);
                setIdForDeleteCourseName(row?._id);
              }}
            >
              {/* <Tooltip title="Delete Question Set" arrow> */}
              <Tooltip title="Löschen Fragensatz" arrow>
                <DeleteIcon />
              </Tooltip>
            </div>
            <>
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsViewMoreAboutus(true);
                  setDataViewMore(row);
                }}
              >
                <Tooltip title="Zeig mehr" arrow>
                  <InfoOutlinedIcon />
                </Tooltip>
              </div>
            </>
            <div className="cursor-pointer">
              <button
                className="bg-warning btn btn-warning ml-2"
                onClick={() => history.push(`/question/${row?._id}`)}
              >
                {/* Question */}
                Aufgaben
              </button>
            </div>
          </>
        );
      },
    },

    // {
    //   name: "Display?",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <div
    //           className="cursor-pointer"
    //           onClick={() => {
    //             setShowStatus(true);
    //             setIdForUpdateCourseStatus(row?._id);
    //             setStatusDisplay(row?.isActive);
    //           }}
    //         >
    //           <Tooltip title="Status Property" arrow>
    //             <div className="cus-medium-button-style widthfixed">
    //               <button className="btn btn-success mr-2">
    //                 {row?.isActive === true ? "Active" : "Deactive"}
    //               </button>
    //             </div>
    //           </Tooltip>
    //         </div>
    //       </>
    //     );
    //   },
    //   sortable: true,
    // },
  ];
  // * Table Style
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  //for search data

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };

  const debouncedSearchTerm = useDebounce(search, 500);

  // Hook
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsLoaderVisible(true);
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllQuestionSet();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllQuestionSet();
    }
  }, [debouncedSearchTerm]);

  //for excel file
  const [allCourseNameExcel, setAllCourseNameExcel] = useState([]);
  const [dataCSV, setDataCSV] = useState([]);

  useEffect(() => {
    if (allCourseNameExcel) {
      allCourseNameExcel.map((registerUser, key) => {
        let data = {
          Number: key + 1,
          CreatedAt: moment(registerUser?.createdAt).format("ll"),
          QuestionName: registerUser?.Qname,
          language: registerUser?.language,
          type: registerUser?.type,
          // weight: registerUser?.weight,
          Category: registerUser?.Category?.name,
          image: registerUser?.image,
        };
        setDataCSV((currVal) => [...currVal, data]);
      });
    }
  }, [allCourseNameExcel]);

  const [mcqCheck, setMcqCheck] = useState(false);
  const [checkBoxCheck, setCheckBoxCheck] = useState(false);
  function typeChanges(e) {
    if (e.target.value === "mcq") {
      setMcqCheck(true);
      setCheckBoxCheck(false);
    } else {
      setCheckBoxCheck(true);
      setMcqCheck(false);
    }
  }

  const [option, setOption] = useState([
    {
      no: "1",
      name: "",
      istrue: false,
    },
    {
      no: "2",
      name: "",
      istrue: false,
    },
    {
      no: "3",
      name: "",
      istrue: false,
    },
    {
      no: "4",
      name: "",
      istrue: false,
    },
  ]);
  const handleQuestion = (data, index) => {
    let list = [...option];
    let i = index;
    let type = data.target.type;
    let value;
    if (type === "radio") {
      value = data.target.value === "on" ? true : false;
    } else {
      value = data.target.value;
    }
    if (i === 0) {
      if (type === "radio") {
        list[i].istrue = value;
        list.map((item, index) => {
          if (index !== i) {
            list[index].istrue = !value;
          }
        });
      } else if (type === "text") {
        list[index].name = value;
      }
    } else if (i === 1) {
      if (type === "radio") {
        list[i].istrue = value;
        list.map((item, index) => {
          if (index !== i) {
            list[index].istrue = !value;
          }
        });
      } else if (type === "text") {
        list[i].name = value;
      }
    } else if (i === 2) {
      if (type === "radio") {
        list[i].istrue = value;
        list.map((item, index) => {
          if (index !== i) {
            list[index].istrue = !value;
          }
        });
      } else if (type === "text") {
        list[i].name = value;
      }
    } else if (i === 3) {
      if (type === "radio") {
        list[i].istrue = value;
        list.map((item, index) => {
          if (index !== i) {
            list[index].istrue = !value;
          }
        });
      } else if (type === "text") {
        list[i].name = value;
      }
    } else {
      setOption(option);
    }
    setOption(list);
  };
  const handleQuestionCheckBox = (data, index) => {
    let list = [...option];
    let i = index + 1;
    let type = data.target.type;

    let value;
    if (type === "checkbox") {
      value = data.target.value === "on" ? true : false;
    } else {
      value = data.target.value;
    }

    if (i === 1) {
      if (type === "checkbox") {
        list[i - 1].istrue = !list[i - 1].istrue;
      } else if (type === "text") {
        list[i - 1].name = value;
      }
    } else if (i === 2) {
      if (type === "checkbox") {
        list[i - 1].istrue = !list[i - 1].istrue;
      } else if (type === "text") {
        list[i - 1].name = value;
      }
    } else if (i === 3) {
      if (type === "checkbox") {
        list[i - 1].istrue = !list[i - 1].istrue;
      } else if (type === "text") {
        list[i - 1].name = value;
      }
    } else if (i === 4) {
      if (type === "checkbox") {
        list[i - 1].istrue = !list[i - 1].istrue;
      } else if (type === "text") {
        list[i - 1].name = value;
      }
    } else {
      setOption(option);
    }
    setOption(list);
  };

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Question Set </h2> */}
              <h2 className="pl-3 pt-2">Lernüberprüfung</h2>
            </div>
            {/* <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="search"
                  value={search}
                  placeholder="Search Question "
                  onChange={(e) => handleSearch(e)}
                />
              </div>
            </div> */}
            <div className="cus-medium-button-style button-height">
              <button
                onClick={() => {
                  setIsAddCourseName(true);
                }}
                className="btn btn-warning mr-2 ml-2"
              >
                {/* Add Question Set */}
                Lernüberprüfung hinzufügen
              </button>
            </div>
          </div>

          {/* delete model */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Warnung!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Are You Sure To Want To delete this Lernüberprüfung */}
              Möchten Sie diesen Lernüberprüfung sicher löschen?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {/* cancel */}Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  handleDeleteCourseName();
                }}
              >
                {/* Delete */}Löschen
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end delete model */}

          <DataTable
            columns={columns}
            data={filteredCourseName}
            customStyles={customStyles}
            style={{
              marginTop: "-3rem",
            }}
            progressPending={isLoaderVisible}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        </div>
      </div>

      {isAddCourseName ? (
        <Dialog
          fullScreen
          open={isAddCourseName}
          onClose={handleAddAdminClose}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleAddAdminClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAddCourseName === true ? (
              <div className="form ml-30 ">
                {/* Name Amenintie */}
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Enter Question Set Name */} Titel
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="name"
                        name="name"
                        value={inputValueForAdd.name}
                        onChange={(e) => {
                          handleOnChnageAdd(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errorsForAdd["name"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Enter Question Set Description */}
                    Beschreibung
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="description"
                        name="description"
                        value={inputValueForAdd.description}
                        onChange={(e) => {
                          handleOnChnageAdd(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errorsForAdd["description"]}
                    </span>
                  </div>
                </div>
               { !isEditPopUp && <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Enter Question Set Description */} Users
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <Select
                        // className="form-control"
                        options={names}
                        // placeholder="Type or paste email addresses and press `Enter`..."
                        // onKeyDown={(e) => handleKeyDown(e)}
                        // value={names.filter(function(names) {
                        //   return names.value === selectedNames;
                        // })}
                        onChange={handleUsers}
                        isMulti
                        required
                        // onPaste={(e) => handlePaste(e)}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errorsForAdd["users"]}
                    </span>
                  </div>
                </div>}

                <div className="d-flex align-items-center justify-content-center">
                  <button
                    onClick={(e) => {
                      isEditPopUp === false
                        ? handelAddCourseNameDetails(e)
                        : handelUpdateCourseNameDetails(e);
                    }}
                    className="btn btn-warning mr-2"
                  >
                    <span>
                      {/* {isEditPopUp === false ? "Add" : "Edit"} Question Set{" "} */}
                      Speichern
                    </span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}

      {isViewMoreAboutus ? (
        <Dialog
          fullScreen
          open={isViewMoreAboutus}
          onClose={handleViewMoreClose}
          TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleViewMoreClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isViewMoreAboutus === true ? (
              <div className="honda-container">
                <div className="other-information-child-text-style1">
                  <h2>Lernüberprüfung Information</h2>
                </div>
                <div className="honda-text-grid12 honda-text-grid-border">
                  <div className="honda-text-grid-items">
                    <span>Name:</span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: dataViewMore?.name,
                      }}
                      className=""
                    />
                  </div>
                </div>
                <div className="honda-text-grid12 honda-text-grid-border">
                  <div className="honda-text-grid-items">
                    <span>{/*Description:*/}Beschreibung</span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: dataViewMore?.description,
                      }}
                      className=""
                    />
                  </div>
                </div>
                <div className="honda-text-grid12 honda-text-grid-border">
                  <div className="honda-text-grid-items">
                    <span>{/*Description:*/}Users</span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: dataViewMore?.users,
                      }}
                      className=""
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}

      <Modal show={showStatus} onHide={handleCloseShowStatus}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Warnung!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Frage Set{statusDisplay === true ? "Deaktivieren" : "Aktivieren"} 
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseShowStatus}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              handleUpdateStatusProperty(!statusDisplay);
            }}
          >
            {statusDisplay === true ? "Deaktivieren" : "Aktivieren"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QuestionSet;
