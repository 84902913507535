import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const CourseCategory = () => {
  let userInfo = getUserInfo();

  const [isType, setIsType] = useState<any>();
  const [inputValue, setInputValue] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [inputValueForAdd, setInputValueForAdd] = useState<any>();
  const [errorsForAdd, setErrorsForAdd] = useState<any>({});
  const [isEditApi, setIsEditApi] = useState<any>(false);
  const [idForEditType, setIdForEditType] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [show, setShow] = useState<any>(false);
  const [IdforDelete, setIdForDelete] = useState();
  const [filteredReport, setFilteredReport] = useState<any>();
  const [users1, setUsers1] = useState();

  const [IdforDeleteCoursecat, setIdForDeleteCoursecat] = useState<any>();//active deactive course category
  const [showStatus, setShowStatus] = useState<any>(false); //for active-deactive status modal for course category
  const [statusDisplay, setStatusDisplay] = useState<any>(false);
 
  const Validation = () => {
    let error: any = {};
    let Formvalid = true;
    if (!inputValue.name) {
      Formvalid = false;
      error["name"] = "Kategorie Titel ist nötig"
    }
    if (!inputValue.description) {
      Formvalid = false;
      error["description"] = "Beschreibung ist nötig"
    }
    setErrors(error);
    return Formvalid;
  }

  const handleCloseShowStatus = () => {
    setShowStatus(false);
  }

  const handleStatusModal = (row: any) => {
    setStatusDisplay(row.isActive);
    setIdForDeleteCoursecat(row?._id);
    setShowStatus(true);

  };

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "name" && value.charAt(e.length)== " ") return;
    if (name === "description" && value.charAt(e.length)== " ") return;
    else {
      setInputValue({ ...inputValue, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
    console.log("inputValue", inputValue);
  };

  const handleAddInvoice = () => {
    setIsType(false);
    setIsEditApi(false);
    setErrors({});
    setInputValue({});
  };

  const handleClose = () => {
    setShow(false);
  }

  useEffect(() => {
    getAllCategory();
  }, []);

  const handleSearchData = (e: any) => {
    console.log("first", e.target.value);
    var value = e.target.value.toLowerCase();
    setUserData(() =>
      filteredReport?.filter(
        (item: any) =>
          item?.name?.toLowerCase().includes(value)
      )
    )
  }

  const getAllCategory = async () => {
    setLoading(true)
    await ApiGet(`category/get-all-category?aid=${userInfo?._id}`)
      .then((res: any) => {
        setLoading(false)
        if (res?.data?.result === 0) {
          console.log("get category", res?.data?.payload?.category);
          res.data.payload.category.map((e: any, index: any) => {
            e.index = index + 1;
            return e;
          });
          setUserData(res?.data?.payload?.category);
          setFilteredReport(res?.data?.payload?.category)
        }

      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleDragEnd1 = async (e: any) => {
    if (!e.destination) return;
    console.log("aaaaaaa", e.destination);

    let tempData = Array.from(userData);
    console.log("bbbbbbb", tempData);

    let [source_data] = tempData.splice(e.source.index, 1);
    console.log("ccccccccccc", e.source.index);
    tempData.splice(e.destination.index, 0, source_data);
    console.log("dddddddd", e.destination.index);

    setUsers1(userData);

    // getAllCourses();

    console.log(" e.source.draggableId1111", e.draggableId.split(",")[0]);
    let Data = {
      cid: e.draggableId,
      aid: userInfo?._id,
      // course_id: e.draggableId.split(",")[0],
      oldPosition: e.source.index + 1,
      newPosition: e.destination.index + 1,
    };

    // userData.append("name", e.source.index);
    // userData.append("description",e.destination.index);

    setLoading(true);
    await ApiPut(`category/update-categoryNumber`, Data)
      .then((res: any) => {
        setLoading(false);
        if (res?.status === 200) {
          toast.success("Daten erfolgreich bearbeiten", { theme: "colored" });
          console.log("editAdmin", res);
       
          getAllCategory();
          setInputValue({});
          setInputValueForAdd({});
          setIsEditApi(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  console.log("category", userData)

  const AddCategory = async (e: any) => {
    inputValue.aid = userInfo?._id;

    if (Validation()) {
      setLoading(true)
      await ApiPost(`category/add-category`, inputValue)
        .then((res: any) => {
          setLoading(false)
          if (res?.status === 200) {
            toast.success("Daten erfolgreich hinzugefügt", { theme: "colored" });
            console.log("add type", res);
            setInputValue({});
            setIsType(false);
            getAllCategory();
            setInputValueForAdd({});
          } else {
            toast.error(res?.data?.message, { theme: "colored" });
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log("err", err);
          toast.error(err?.response?.data?.message, { theme: "colored" });
        });
    }

  };

  const editCategory = async (e: any) => {
    setLoading(true)
    await ApiPut(`category/update-category/${idForEditType}`, inputValue)
      .then((res: any) => {
        setLoading(false)
        if (res?.status === 200) {
          toast.success("Daten erfolgreich aktualisiert", { theme: "colored" });
          console.log("editcatogory", res);
          setIsType(false);
          getAllCategory();
          setInputValue({});
          setInputValueForAdd({});
          setIsEditApi(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false)
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  //   const DeleteCategory = async() =>{
  //     await ApiDelete(`category/delete-category/${IdforDelete}`)

  //            .then((res: any) => {
  //                console.log("status res", res);
  //                if (res?.status === 200) {
  //                    setShow(false);
  //                    toast.success("Data Delete Successfully", { theme: "colored" });
  //                    setInputValue({});
  //                    getAllCategory();
  //                } else {
  //                    toast.error(res?.data?.message,{ theme: "colored" } );
  //                }
  //            })
  //            .catch((err) => {
  //                console.log("err", err);
  //                toast.error(err?.response?.data?.message, { theme: "colored" });
  //            })          
  //  };

  const handleDeletCoursecategory = (status: any) => {
    //update active-deactive status
    ApiDelete(`category/delete-category/${IdforDeleteCoursecat}?isActive=${status}`)
      .then((res: any) => {
        console.log("status res", res);
        if (res?.status === 200) {
          setShowStatus(false);
          toast.success("Daten erfolgreich aktualisiert", { theme: "colored" });
          setInputValue({});
          // getAllCourses();
          getAllCategory();
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row: any) => {
        return row?.index;
      },
      width: "65px",
    },
    {
      name: "Kategorie Name",
      selector: (row: any) => row?.name,
      sortable: true,
    },
    {
      name: "Beschreibung",
      selector: (row: any) => row?.description,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row: any) => {
        return (
          <>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleStatusModal(row);
              }}
            >
              <Tooltip title="Modul deaktivieren" arrow>
                <span className="badge badge-secondary w-100">
                  {row?.isActive === true ? "Aktiv" : "Deaktiv"}
                </span>
              </Tooltip>
            </div>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Einstellungen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2 pr-2"
                onClick={() => {
                  setIsType(true);
                  console.log("rows", row);
                  setIsEditApi(true);
                  setIdForEditType(row?._id);
                  getAllCategory();
                  setInputValue({
                    name: row?.name,
                    description: row?.description
                  });
                }}
              >
                <Tooltip title="Bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
            </div>

            {/* <div
              className="cursor-pointer"
              onClick={(e) => {
                console.log("delete catagory",e.target);
                 setIdForDelete(row?._id);
                setShow(true);               
              }}
            >
              <Tooltip title="Delete Reminder" arrow>
                <DeleteIcon />
              </Tooltip>
            </div> */}
          </>
        );
      }
    },

  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Module Kategorie</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="title"
                  placeholder="Suche Module Kategorie"
                  onChange={(e) => handleSearchData(e)}
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-warning mr-2"
                onClick={() => {
                  setIsType(true);
                  // getAllCategory();
                }}
              >
                Kategorie hinzufügen
                {/* Add Category */}

              </button>
            </div>
          </div>

          {/* active-deactive model for course category */}
          <Modal show={showStatus} onHide={handleCloseShowStatus}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Warnung!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            Module Kategorie{" "}
              {statusDisplay === true ? "Deaktivieren" : "Aktivieren"} ?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseShowStatus}>
                Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={(e: any) => {
                  handleDeletCoursecategory(!statusDisplay);
                }}
              >
                {statusDisplay === true ? "Deaktivieren" : "Aktivieren"}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end active-deactive model for course category*/}
          {userData?.length !== 0 ? (
            <DragDropContext onDragEnd={handleDragEnd1}>
              <table className="table borderd">
                <thead>
                  <tr>
                    <th />
                    {/* <th>SNr.</th> */}
                    <th>Kategorie Name</th>
                    <th>Beschreibung</th>
                    <th>Status</th>
                    {/* <th>Lektionen</th> */}
                    <th>Einstellungen</th>
                    {/* <th>Gender</th>
              <th>Gender</th> */}
                  </tr>
                </thead>
                <Droppable droppableId="droppable-1">
                  {(provider) => (
                    <tbody
                      className="text-capitalize"
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                    >
                      {userData?.map((row: any, index: any) => (
                        <Draggable
                          key={row.name}
                          draggableId={row?._id }
                          index={index}
                        >
                          {(provider) => (
                            <tr
                              {...provider.draggableProps}
                              ref={provider.innerRef}
                            >
                              <td {...provider.dragHandleProps}> = </td>
                              {/* <td>{index + 1}</td> */}
                              <td>{row?.name}</td>
                              <td>{row?.description}</td>

                              <td>
                                {" "}
                                <>
                                  {
                                    <>
                                     <div
              className="cursor-pointer"
              onClick={() => {
                handleStatusModal(row);
              }}
            >
              <Tooltip title="Modul deaktivieren" arrow>
                <span className="badge badge-secondary w-100">
                  {row?.isActive === true ? "Aktiv" : "Deaktiv"}
                </span>
              </Tooltip>
            </div>
                                    </>
                                  }
                                </>
                              </td>
                              <td>
                                {" "}
                                <>
                                  {
                                    

                                    <>
                                      <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2 pr-2"
                onClick={() => {
                  setIsType(true);
                  console.log("rows", row);
                  setIsEditApi(true);
                  setIdForEditType(row?._id);
                  getAllCategory();
                  setInputValue({
                    name: row?.name,
                    description: row?.description
                  });
                }}
              >
                <Tooltip title="Bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
            </div>
                                    </>
                                  }
                                </>
                              </td>
                            
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provider.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
          ) : (
            <h4 style={{ textAlign: "center" }}>Keine Daten gefunden</h4>
          )}
          {/* <DataTable columns={columns} data={userData}
            progressPending={loading}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
            pagination /> */}
        </div>
      </div>

      {isType ? (
        <Dialog
          fullScreen
          open={isType}
          onClose={handleAddInvoice}
        // TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleAddInvoice}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isType === true ? (
              <div className="form ml-30 ">
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Kategorie Titel
                    {/* Category name */}

                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        placeholder="Eintreten module kategorie"
                        className={`form-control form-control-lg form-control-solid `}
                        id="name"
                        name="name"
                        value={inputValue.name}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["name"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Beschreibung */}
                    Beschreibung
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <textarea
                        placeholder="Eintreten bezeichnung"
                        className={`form-control form-control-lg form-control-solid `}
                        id="description"
                        name="description"
                        value={inputValue.description}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["description"]}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-fillcolor mr-2">
                      {/* <span>{isEditApi ? "Edit" : "Add"} Category</span> */}
                      <span>Speichern</span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning mr-2"
                      onClick={(e) => {
                        isEditApi ? editCategory(e) : AddCategory(e);
                      }}
                    >
                      {/* <span>{isEditApi ? "Edit Category" : "Add Category"}</span> */}
                      <span>Speichern</span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}
    </>
  );
};

export default CourseCategory;
