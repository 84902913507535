import { render } from 'react-dom';
// import { flushSync } from 'react-dom';
import './board1.scss';
import * as React from 'react';
// import { extend } from '@syncfusion/ej2-base';
import { extend, closest, remove, addClass, L10n } from "@syncfusion/ej2-base";
import { ToastContainer, toast } from "react-toastify";
import * as dataSource from './datasource.json';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-kanban";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { SampleBase } from './sample-base';
import { PropertyPane } from './property-pane';
import { ApiDelete, ApiGet, ApiPost, ApiPut } from '../../../helpers/API/ApiData';
import { getUserInfo } from '../../../utils/user.util';
// import * as userUtil from "../../utils/user.util";
/**
 * Kanban Overview sample
 */
export class Board extends SampleBase {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: getUserInfo(),
      addData: {},
      data: extend([], [], true),
      board: extend([], [], true)
    };
    // L10n.load({
    //   "en-US": {
    //     kanban: {
    //       'addTitle': 'Neue Karte hinzufügen',
    //       'editTitle': 'Kartendetails bearbeiten',
    //       'save': 'speichern',
    //       'delete': 'Löschen',
    //       'cancel': 'Stornieren',
    //     },
    //   },
    // });

  }

  kanbanObj;
  addClick() {
    console.log("kanbanObj", this.kanbanObj.kanbanData);
    const cardIds = this.kanbanObj.kanbanData?.length ? this.kanbanObj.kanbanData.map((obj) => parseInt(obj.ID.replace('Task ', ''), 10)) : [0];
    console.log("cardIds", cardIds);
    const cardCount = Math.max.apply(Math, cardIds) + 1;
    console.log("cardCount", cardCount);
    const cardDetails = { ID: "Task " + cardCount, Status: "Open", Priority: "Normal", Assignee: "Andrew Fuller", Estimate: 0, Tags: "", Summary: "" };
    this.kanbanObj.openDialog('Add', cardDetails,);

  }
  getBoardDataName() {
    if(this.state.userInfo?.position==="Admin"){
      console.log("---------------aaaaaaaaaaaaaaaaaaa")
      ApiGet(`boardName/get-all-boardName?aid=${this.state.userInfo?._id}`)
      .then((res1) => {
        console.log("res1aaaaaa",res1)
        this.setState({ ...this.state, board: extend([], res1?.data?.payload?.boardName, true) })
      })
      .catch((err) => {
        console.log("err", err);
      });
    }
    else{
      console.log("------------tlllllllllllllllll");
      ApiGet(`boardName/get-all-boardName?tlid=${this.state.userInfo?._id}`)
      .then((res1) => {
        console.log("res2tlllllllll",res1)
        this.setState({ ...this.state, board: extend([], res1?.data?.payload?.boardName, true) })
      })
      .catch((err) => {
        console.log("err", err);
      });
    }
  }

  getBoardData() {
    ApiGet(`board/get-all-board?uid=${this.props.match.params.id}`)
      .then(async (res) => {
        this.setState({ ...this.state, data: extend([], res?.data?.payload?.board, true) })
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  componentDidMount() {
    // let userInfo1 = userUtil.getUserInfo();
    this.getBoardDataName()
    this.getBoardData()
  }

  dialogTemplate(props) {
    return (<KanbanDialogFormTemplate {...props}
      statusData={this.state.board && this.state.board?.length ?
        this.state.board.map((record) => { return record?.name; })
        : []}
      onChangeData={(data) => {
        delete data.onChangeData;
        this.setState({ ...this.state, addData: data })
      }} />);
  }
  dialogOpen(args) {
    console.log("argss", args);
    if (args.requestType == 'Edit') {
    }
  }
  onActionBegin(args) {
    if (args?.addedRecords.length > 0) {
      const datas = {
        uid: this.props.match.params.id,
        ID: args?.addedRecords[0]?.ID,
        Status: args?.addedRecords[0]?.Status,
        Priority: args?.addedRecords[0]?.Priority,
        Summary: args?.addedRecords[0]?.Summary
      };

      ApiPost(`board/add-board`, datas)
        .then((res) => {
          toast.success(res?.data?.message);
          ApiGet(`board/get-all-board?uid=${this.props.match.params.id}`)
            .then((res) => {
              this.setState({ ...this.state, data: extend([], res?.data?.payload?.board, true) })
            })
            .catch((err) => {
              console.log("err", err);
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
    if (args?.changedRecords.length > 0) {

      const datas = {
        uid: this.props.match.params.id,
        ID: args?.changedRecords?.[0]?.ID,
        Status: args?.changedRecords?.[0]?.Status,
        Priority: args?.changedRecords?.[0]?.Priority,
        Summary: args?.changedRecords?.[0]?.Summary
      };
      ApiPut(`board/update-board/${args?.changedRecords?.[0]?._id}`, datas)
        .then((res) => {
          toast.success(res?.data?.message);
          ApiGet(`board/get-all-board?uid=${this.props.match.params.id}`)
            .then((res) => {
              this.setState({ ...this.state, data: extend([], res?.data?.payload?.board, true) })

            })
            .catch((err) => {
              console.log("err", err);
            });
        })
        .catch((err) => {
          toast.error(err);
        });
    }
    if (args?.deletedRecords.length > 0) {
      ApiDelete(`board/delete-board/${args?.deletedRecords?.[0]?._id}`)
        .then((res) => {
          toast.success(res?.data?.message);
          ApiGet(`board/get-all-board?uid=${this.props.match.params.id}`)
            .then((res) => {
              this.setState({ ...this.state, data: extend([], res?.data?.payload?.board, true) })
            })
            .catch((err) => {
              console.log("err", err);
            });
        })
        .catch((err) => {
          toast.error(err);
        });

    }
  }
  render() {

    return (
      <>
        <ToastContainer />
        <div className='schedule-control-section'>
          <div className="">
            <div className="page-title display">
              <div>
                <h1>To Do Board</h1>
              </div>
              <div className="col-lg-3 property-section">
                <table id="property" title="Properties">
                  <tbody>
                    <tr>
                      <td>
                        {/* <ButtonComponent id='addNew' className="btns" onClick={this.addClick.bind(this)}>Add New Card</ButtonComponent> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {this.state?.board && this.state?.board?.length > 0 &&
              <div className='kanban-control-section'>
                <div className='col-lg-12 control-section'>
                  <div className='control-wrapper'>
                    <div className='kanban-section'>
                      <KanbanComponent id="kanban" ref={(kanban) => { this.kanbanObj = kanban; }} allowDragAndDrop={false}
                        dialogOpen={false}
                        keyField="Status" dataSource={this.state.data} cardSettings={{ contentField: "Summary", headerField: "ID" }}
                        actionComplete={this.onActionBegin.bind(this)}

                        dialogSettings={{ template: this.dialogTemplate.bind(this) }}>
                        <ColumnsDirective>
                          {
                            this.state?.board && this.state.board?.length > 0 ?
                              this.state.board?.map((record, index) => {
                                return (
                                  <ColumnDirective headerText={record?.name} keyField={record?.name} />
                                )
                              })
                              :
                              null
                          }
                        </ColumnsDirective>
                        {/* <ColumnDirective headerText="To Do" keyField="Open" />
                        <ColumnDirective headerText="In Progress" keyField="InProgress" />
                        <ColumnDirective headerText="Testing" keyField="Testing" />
                        <ColumnDirective headerText="Done" keyField="Close" /> */}
                      </KanbanComponent>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

        </div>
      </>
    );
  }
}
export class KanbanDialogFormTemplate extends React.Component {
  // assigneeData = [
  //   'Nancy Davloio', 'Andrew Fuller', 'Janet Leverling',
  //   'Steven walker', 'Robert King', 'Margaret hamilt', 'Michael Suyama'
  // ];
  statusData = this.props.statusData;
  priorityData = ['Low', 'Normal', 'Critical', 'Release Breaker', 'High'];
  // tagsHtmlAttributes = { name: "Tags" };
  constructor(props) {
    super(props);
    this.state = extend({}, {}, props, true);
  }
  onChange(args) {
    let key = args.target.name;
    let value = args.target.value;
    this.setState({ [key]: value });
    this.props.onChangeData(this.state);
  }
  render() {
    let data = this.state;
    return (<div>
      <table>
        <tbody>
          <tr>
            <td className="e-label">ID</td>
            <td>
              <div className="e-float-input e-control-wrapper">
                <input id="ID" name="ID" type="text" className="e-field" value={data.ID} disabled />
              </div>
            </td>
          </tr>
          <tr>
            <td className="e-label">Status</td>
            <td>
              <DropDownListComponent id='Status' name="Status" dataSource={this.statusData} className="e-field" placeholder='Status' value={data.Status}></DropDownListComponent>
            </td>
          </tr>
          {/* <tr>
            <td className="e-label">Assignee</td>
            <td>
              <DropDownListComponent id='Assignee' name="Assignee" className="e-field" dataSource={this.assigneeData} placeholder='Assignee' value={data.Assignee}></DropDownListComponent>
            </td>
          </tr> */}
          <tr>
            <td className="e-label">Priority</td>
            <td>
              <DropDownListComponent type="text" name="Priority" id="Priority" popupHeight='300px' className="e-field" value={data.Priority} dataSource={this.priorityData} placeholder='Priority'></DropDownListComponent>
            </td>
          </tr>
          <tr>
            <td className="e-label">Summary</td>
            <td>
              <div className="e-float-input e-control-wrapper">
                <textarea name="Summary" className="e-field" value={data.Summary} onChange={this.onChange.bind(this)}></textarea>
              </div>
            </td>

          </tr>
        </tbody>
      </table>
    </div>);
  }
}


export default Board;