import React, { useEffect, useState } from "react";
import { getToken, createMeeting } from "../Videosdk/api";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import { ToastContainer, toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { userInfo } from "os";
import DataTable, { defaultThemes } from "react-data-table-component";
import moment from "moment";
import Select from "react-select";

const CreateSession = () => {
  const [meetingLink, setMeetingLink] = useState();
  const [hostToken, setHostToken] = useState();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();
  const [names, setNames] = useState();
  const [selectedNames, setSelectedNames] = useState();
  let userInfo = getUserInfo();

  useEffect(() => {
    getAllUsers();
    getNames();
  }, []);

  useEffect(() => {
    if(userInfo?.position==="Team Leader")
    {
        document.title =" Akademie | Teamleiter"; 
    }
    else{
        document.title =" Akademie | Admin"; 
    }
      
  }, []);

  const getAllUsers = async () => {
    setLoading(true);
    // if(!search){
    await ApiGet(`video/get-mail-history?aid=${userInfo?._id}`)
      .then((res) => {
        setLoading(false);
        console.log("get courses", res?.data?.payload?.courses);
        setUsers(res?.data?.payload?.findUser);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getNames = async () => {
    setLoading(true);
    if (userInfo?.position === "Admin") {
      await ApiGet(`admin/getUserListForBoard?&aid=${userInfo?._id}`)
        .then((res) => {
          setLoading(false);
          handleUserNames(res?.data?.payload?.findUsers);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    if (userInfo?.position === "Team Leader") {
      console.log("---panoti---");
      await ApiGet(`admin/getUserByTlid/${userInfo?._id}`)
        .then((res) => {
          console.log("TL users res", res);
          setLoading(false);
          handleUserNames(res?.data?.payload?.user);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleUserNames = (data) => {
    let tempUserName = [];
    data.map((item) =>
      tempUserName.push({ value: item._id, label: item.email })
    );
    setNames(tempUserName);
  };

  const handleRejoinButton = async (meetingId) => {
    const token = await getToken();
    window.open(`//${meetingId}&token=${token}`);
  };

  const columnProvider = () => {
    return [
      {
        name: "SNo",
        cell: (row, index) => index + 1,
        width: "65px",
      },

      {
        name: "Date",
        cell: (row, index) => moment(row?.updatedAt).format("lll "),
        sortable: true,
      },

      {
        name: "Email",
        selector: "email",
        sortable: true,
      },
      {
        name: "Meeting ID",
        cell: (row) => {
          return (
            <>
              <button
                class="btn btn-warning mr-2"
                onClick={() => handleRejoinButton(row?.meetingId)}
              >
                Teilnehmen
              </button>
            </>
          );
        },
        // selector: "meetingId",
        wrap: true,
        sortable: true,
      },
    ];
  };
  const columns = columnProvider();

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  // }else
  const handleCreateSession = async () => {
    const token = await getToken();
    const meetingId = await createMeeting({ token });
    setHostToken(token);
    setMeetingLink(meetingId);
    // window.open(
    //   `//copper-legitimate.buildx.live/?meetingId=${meetingId}&token=${token}&joinScreenEnabled=true`
    // );
  };

  const handleJoinHostSession = () => {
    window.open(
      `//copper-legitimate.buildx.live/?meetingId=${meetingLink}&token=${hostToken}&joinScreenEnabled=true&canDrawOnWhiteboard=true&canToggleWhiteboard=true`
    );
  };

  const [state, setState] = useState({
    items: [],
    value: "",
    error: null,
  });
  const [modal, setModal] = useState(false);
  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setState({ ...state, error });

      return false;
    }
    return true;
  };
  const isInList = (email) => {
    return state.items.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };
  const handleDelete = (item) => {
    setState({
      ...state,
      items: state.items.filter((i) => i !== item),
    });
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));
      setState({
        ...state,
        items: [...state.items, ...toBeAdded],
      });
    }
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = state.value.trim();

      if (value && isValid(value)) {
        setState({
          ...state,
          items: [...state.items, state.value],
          value: "",
        });
      }
    }
  };

  const handleChange = (evt) => {
    setState({
      ...state,
      value: evt.target.value,
      error: null,
    });
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const sendPeperSet = () => {
    const data = {
      email: selectedNames.map((item) => item.label),
      meetingId: `copper-legitimate.buildx.live/?meetingId=${meetingLink}&joinScreenEnabled=true`,
      aid: userInfo?._id,
    };

    ApiPost(`video/send-meetingId`, data)
      .then((res) => {
        if (res?.status == 200) {
          toast.success(res?.data?.message);
          console.log("log", res?.data?.message);
          getAllUsers();
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const closeModal = () => {
    setModal(false);
    setState({
      items: [],
      value: "",
      error: null,
    });
  };
  return (
    <div>
      <ToastContainer />

      {/* <h1>Sitzung erstellen</h1> */}
      <button
        className="btn btn-warning mr-2"
        onClick={() => {
          handleCreateSession();
          setModal(true);
        }}
      >
        Videositzung erstellen
      </button>
      <hr />
      <br />
      <DataTable
        columns={columns}
        data={users}
        customStyles={customStyles}
        style={{
          marginTop: "-3rem",
        }}
      />

      <Modal show={modal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="#3F4254">
            Einladung via E-Mail versenden
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Wählen Sie Nutzer für das Live-Meeting
          <div>
            <br />
            <br />
            <div>
              {/* {state.items.map((item) => (
                <div className="tagItem" key={item}>
                  {item}
                  <button
                    type="button"
                    className="button"
                    onClick={() => handleDelete(item)}
                  >
                    &times;
                  </button>
                </div>
              ))} */}

              {/* <input
                className="form-control"
                value={state.value}
                placeholder="Type or paste email addresses and press `Enter`..."
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => handleChange(e)}
                onPaste={(e) => handlePaste(e)}
              /> */}
              <Select
                // className="form-control"
                options={names}
                // placeholder="Type or paste email addresses and press `Enter`..."
                // onKeyDown={(e) => handleKeyDown(e)}
                onChange={setSelectedNames}
                isMulti
                required
                // onPaste={(e) => handlePaste(e)}
              />

              {/* <Select
                  // defaultValue={this.selectedOption}
                  onChange={this.handleChange}
                  isMulti
                  options={this.userData}
                /> */}

              <div className="py-2">
                {state.error && <p className="error">{state.error}</p>}
              </div>
              <div className="py-2">
                {selectedNames?.length > 0 ? (
                  <>
                    <button
                      type="submit"
                      className="btn btn-warning mr-2"
                      onClick={() => sendPeperSet()}
                    >
                      Einladung senden
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <Modal.Footer>
            {" "}
            <Button
              className="btn btn-warning mr-2"
              variant="primary"
              onClick={handleJoinHostSession}
            >
              Teilnehmen
            </Button>
            <Button variant="secondary" onClick={closeModal}>
              {/* Cancel */}Abbrechen
            </Button>
          </Modal.Footer>{" "}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateSession;
