import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from "../../../helpers/API/ApiData";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { customStyles } from "../Courses/TableStyle";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const AllAdmins = () => {
  let userInfo = getUserInfo();

  const [adminData, setAdminData] = useState<any>();
  const [isType, setIsType] = useState<any>(); //for add admin
  const [inputValue, setInputValue] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [adminloading, setAdminLoading] = useState<any>(false)
  const [inputValueForAdd, setInputValueForAdd] = useState<any>();
  const [isEditApi, setIsEditApi] = useState<any>(false); //for edit admin
  const [idForEditType, setIdForEditType] = useState<any>();

  const [statusDisplay, setStatusDisplay] = useState<any>(false);
  const [showStatus, setShowStatus] = useState<any>(false); //for active-deactive status modal
  const [idForUpdateStatus, setIdForUpdateStatus] = useState("");

  const [adminId, setAdminid] = useState<any>();
  const [Showmodel, setShowmodel] = useState<any>(); //for view user modal
  const [userData, setuserData] = useState<any>();

  const regexEmail =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

  const regexName = /^[a-zA-Z]+ [a-zA-Z]+$/;

  const handleshowModel = (row: any) => {
    setShowmodel(true);
  };

  const Validation = () => {
    let error: any = {};
    let Formvalid = true;
    if (!inputValue.fname) {
      Formvalid = false;
      error["fname"] = "Vorname ist erforderlich";
    }
    if (!inputValue.lname) {
      Formvalid = true;
      error["lname"] = "Nachname ist erforderlich";
    }
    if (!inputValue.email) {
      Formvalid = true;
      error["email"] = "E-mail ist erforderlich";
    }
    if (regexEmail.test(inputValue.email) === false) {
      Formvalid = false;
      error["email"] = "Email ist ungültig";
      console.log(error);
    }
    if (!inputValue.phone) {
      Formvalid = false;
      error["phone"] = "Geben Sie gültige Zahlen ein";
    }
    if (!inputValue.password) {
      Formvalid = false;
      error["password"] = "Passwort ist erforderlich";
    }
    setErrors(error);

    return Formvalid;
  };

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    if (e.target.name === "fname" || e.target.name === "lname") {
      let val = e.target.value.replace(/[^a-zA-Z]/g, "");
      setInputValue({ ...inputValue, [name]: val });
      setErrors({ ...errors, [name]: "" });
    } else {
      setInputValue({ ...inputValue, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleClose = () => {
    setShowmodel(false);
  };

  const handleCloseShowStatus = () => {
    setShowStatus(false);
  };

  const handleStatusModal = (row: any) => {
    setStatusDisplay(row.isActive);
    setIdForUpdateStatus(row._id);
    setShowStatus(true);
  };

  const handleAddInvoice = () => {
    setIsType(false);
    setIsEditApi(false);
    setErrors({});
    setInputValue({});
  };

  useEffect(() => {
    getAllAdmins();
  }, []);

  const getAllUsers = async (aid: any) => {
    setAdminid(aid);
    setLoading(true)
    await ApiGet(`admin/getUserByAid/${aid}`)
      .then((res: any) => {
        setLoading(false)
        console.log("get users", res);
        if (res?.data?.result === 0) {
          setuserData(res?.data?.payload?.user);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAllAdmins = async () => {
    setAdminLoading(true)
    await ApiGet(`admin/get-admins`)
      .then((res: any) => {
        setAdminLoading(false)
        console.log("get admin", res);
        res.data.payload.admin.map((e: any, index: any) => {
          e.index = index + 1;
          return e;
        });
        setAdminData(res?.data?.payload?.admin.reverse());
      })
      .catch((err) => {
        console.log("err", err?.response);
      });
  };


  console.log("adminData", adminData);


  const addAdmin = async (e: any) => {
    inputValue.position = "Admin";
    inputValue.role = "6257a045023f000a001af0a4";

    // inputValue.role=userInfo?.role
    if (Validation()) {
      setLoading(true);
      await ApiPost(`admin/signup`, inputValue)
        .then((res: any) => {
          setLoading(false)
          if (res?.status === 200) {
            setLoading(false);
            toast.success("Admin fügte erfolgreich hinzu", { theme: "colored" });
            console.log("add type", res);
            setInputValue({});
            setIsType(false);
            getAllAdmins();
            setInputValueForAdd({});
          } else {
            toast.error(res?.data?.message, { theme: "colored" });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
          toast.error(err?.response?.data?.message, { theme: "colored" });
        });
    }
  };

  const handelUpdateAdminStatus = (status: any) => {
    //update active-deactive status
    ApiDelete(`admin/deleteAdmin/${idForUpdateStatus}?isActive=${status}`)
      .then((res: any) => {
        console.log("status res", res);
        if (res?.status === 200) {
          setShowStatus(false);
          toast.success("Daten erfolgreich aktualisiert", { theme: "colored" });
          setInputValue({});
          getAllAdmins();
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const EditAdmin = async (e: any) => {
    setLoading(true);
    await ApiPut(`admin/updateAdmin/${idForEditType}`, inputValue)
      .then((res: any) => {
        setLoading(false);
        if (res?.status === 200) {
          toast.success("Admin wurde erfolgreich bearbeitet", { theme: "colored" });
          console.log("editAdmin", res);
          setIsType(false);
          getAllAdmins();
          setInputValue({});
          setInputValueForAdd({});
          setIsEditApi(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  let indexFind = 1;

  const columns = [
    {
      name: "SNr.",
      cell: (row: any) => {
        return indexFind++;
      },
      width: "65px",
    },
    {
      name: "Name",
      selector: (row: any) => row?.fname + " " + row?.lname,
      sortable: true,
    },
    {
      name: "E-Mail",
      selector: (row: any) => row?.email,
      sortable: true,
    },
    {
      name: "Telefonnummer",
      selector: (row: any) => row.phone,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row: any) => {
        return (
          <>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleStatusModal(row);
              }}
            >
              <Tooltip title="Modul deaktivieren" arrow>
                <span className="badge badge-secondary w-100">
                  {row?.isActive === true ? "Aktiv" : "Deaktiv"}
                </span>
              </Tooltip>
            </div>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setIsType(true);
                  console.log("rows", row);
                  setIsEditApi(true);
                  // setInputValueForAdd({
                  //     file: row?.photo,
                  // });
                  setIdForEditType(row?._id);
                  getAllAdmins();
                  setInputValue({
                    fname: row?.fname,
                    lname: row?.lname,
                    email: row?.email,
                    phone: row?.phone,
                  });
                }}
              >
                <Tooltip title="Bearbeiten" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  console.log("show more", row);
                  setAdminid(row?._id);
                  handleshowModel(row);
                  getAllUsers(row?._id);
                }}
              >
                <Tooltip title="Zeig mehr" arrow>
                  <InfoOutlined />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "180px",
    },
  ];

  const columns1 = [
    {
      name: "SNr.",
      cell: (row: any, index: any) => index + 1,
      width: "65px",
    },
    {
      name: "Name",
      cell: (row: any, index: any) => row?.fname + " " + row?.lname,
    },
    {
      name: "E-Mail",
      cell: (row: any, index: any) => row?.email,
    },
    {
      name: "Telefonnummer",
      cell: (row: any, index: any) => row?.phone,
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Admins</h2>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-warning mr-2"
                onClick={() => {
                  setIsType(true);
                  // getAllCategory();
                }}
              >
                Hinzufügen Admin
              </button>
            </div>
          </div>

          {/* active-deactive model */}
          <Modal show={showStatus} onHide={handleCloseShowStatus}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">Warnung!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            Admin
              {statusDisplay === true ? "Deaktivieren" : "Aktivieren"}  !
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseShowStatus}>
                Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={(e: any) => {
                  handelUpdateAdminStatus(!statusDisplay);
                }}
              >
                {statusDisplay === true ? "Deaktivieren" : "Aktivieren"}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end active-deactive model */}

          <DataTable columns={columns} data={adminData}
            progressPending={adminloading}
            progressComponent={
              <Loader
                type="Puff"
                color="#334D52"
                height={30}
                width={30}
              />
            }
            pagination
          />
        </div>
      </div>

      {isType ? (
        <Dialog
          fullScreen
          open={isType}
          onClose={handleAddInvoice}
        // TransitionComponent={Transition}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleAddInvoice}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isType === true ? (
              <div className="form ml-30 ">
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* First name */}Vorname
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="fname"
                        name="fname"
                        value={inputValue?.fname}

                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["fname"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Last name */}Nachname
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="lname"
                        name="lname"
                        value={inputValue?.lname}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["lname"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    E-Mail
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="email"
                        className={`form-control form-control-lg form-control-solid `}
                        id="email"
                        name="email"
                        value={inputValue?.email}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["email"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Telefonnummer
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="number"
                        className={`form-control form-control-lg form-control-solid `}
                        id="phone"
                        name="phone"
                        value={inputValue?.phone}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["phone"]}
                    </span>
                  </div>
                </div>

                {!isEditApi && (
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Passwort
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <input
                          type="password"
                          className={`form-control form-control-lg form-control-solid `}
                          id="password"
                          name="password"
                          value={inputValue?.password}
                          onChange={(e) => {
                            handleOnChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["password"]}
                      </span>
                    </div>
                  </div>
                )}

                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-warning mr-2">
                      {/* <span>{isEditApi ? "Edit" : "Add"} Admin</span> */}
                      <span>Speichern</span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning mr-2"
                      onClick={(e) => {
                        isEditApi ? EditAdmin(e) : addAdmin(e);
                      }}
                    >
                      {/* <span>{isEditApi ? "Edit Admin" : "Add Admin"}</span> */}
                      <span>Speichern</span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}

      {Showmodel ? (
        <Dialog fullScreen open={Showmodel} onClose={handleClose}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {Showmodel === true ? (
              <div className="card p-1">
                <div className="p-2 mb-2">
                  <div className="d-flex justify-content-between mb-2">
                    <div className="col-md-3 col-sm-12 d-flex justify-content-between">
                      <h2 className="pl-3 pt-2">Alle Benutzer</h2>
                    </div>
                  </div>

                  <DataTable
                    columns={columns1}
                    data={userData}
                    customStyles={customStyles}
                    style={{
                      marginTop: "-3rem",
                    }}
                    progressPending={loading}
                    progressComponent={
                      <Loader
                        type="Puff"
                        color="#334D52"
                        height={30}
                        width={30}
                      />
                    }
                    highlightOnHover
                    pagination
                  />
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}                                                 
    </>
  );
};

export default AllAdmins;
