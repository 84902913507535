import React, { useEffect, useState } from "react";
import { getUserInfo } from "../../../../utils/user.util";
import AllAdmins from "../../../components/AllAdmins/AllAdmins";

import AllUsers from "../../../components/AllUsers/AllUsers";
import { ApiGet } from "../../../../helpers/API/ApiData";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Logo from "../../../layout/components/Logos/logo.png";
import Courses from "../../../components/Courses/Courses";
import AllCourse from '../../../components/AllAdmins/AllCourse';
import DashboardLogo from '../../../../_metronic/layout/components/Logos/logo4.svg';

// import Inventory from "../../../components/Inventory/Inventory";
// import MarketPlace from "../../../components/MarketPlace/MarketPlace";
// import Order from "../../../components/Order/Order";

export function MixedWidget1({ className }) {
  let userInfo = getUserInfo();

  // const [selectedTable, setSelectedTable] = useState("Users");
  // const [countData, setCountData] = useState({});
  const [
    countDataAll,
    // , setCountDataAll
  ] = useState("");
  const [superAdminData, setsuperAdminData] = useState();
  const [selectTableAdmin, setTableAdmin] = useState("Admins");
  const [subAdminData, setsubAdminData] = useState();
  const [selectTableSubAdmin, setTableSubAdmin] = useState("SubAdmins");
  const [courseData, setCourseData] = useState();
  const [selectTableCourse, setTableCourse] = useState("Course");
  const [num, setNum] = useState(); //no of user
  const [numAdmin, setNumAdmin] = useState();
  const [numCourse, setNumCourse] = useState();
  const [numadminCourse, setNumadminCourse] = useState();

  useEffect(() => {
    document.title = "Akademie | Admin";
  }, []);

  useEffect(() => {
    getcount();
  }, []);

  const getcount = async () => {
    if (userInfo?.position === "Admin") {
      await ApiGet(`admin/getUserByAid/${userInfo?._id}`)
        .then((res) => {
          setNum(res?.data?.payload?.user?.length);
        })
        .catch((err) => {
          console.log("err", err);
        });

      await ApiGet(`course/get-all-course?aid=${userInfo?._id}`)
        .then((res) => {
          setNumCourse(res?.data?.payload?.courses?.length);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    if (userInfo?.position === "Super Admin") {
      await ApiGet(`admin/get-admins`)
        .then((res) => {
          setNumAdmin(res?.data?.payload?.admin?.length);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    if (userInfo?.position === "Super Admin") {
      await ApiGet(`course/get-all-course`)
        .then((res) => {
          console.log("resnse",res);
          setNumadminCourse(res?.data?.payload?.courses?.length);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <div>
      {/* Header */}

      {userInfo?.position === "Team Leader" && (
        <div className="honda-logo-center-alignment-page">
        <img src={DashboardLogo} />
      </div>
      )}

      {userInfo?.position === "Super Admin" && (
        <div className="card-header border-0 bg-danger py-1 px-1">
          <div className="card-body p-0 position-relative overflow-hidden">
            {userInfo?.position === "Super Admin" ? (
              <>
                <div className="card-spacer">
                  <div className="row m-5">
                    <div className="col bg-light-warning px-6 py-8 rounded-xl mr-5">
                      <span className="text-danger font-weight-bold font-size-h3 d-block my-2">
                        {superAdminData?.totalUser}
                      </span>
                      <a
                        href="#"
                        className="text-danger font-weight-bold font-size-h6"
                        onClick={() => setTableAdmin("Admins")}
                      >
                        {/* Total products : {Pronum ? Pronum : 0} */}
                        Admin Gesamt : {numAdmin ? numAdmin : 0}
                      </a>
                    </div>

                    <div className="col bg-light-warning px-6 py-8 rounded-xl mr-5">
                      <span className="text-danger font-weight-bold font-size-h3 d-block my-2">
                        {superAdminData?.totalArtist}
                      </span>
                      <a
                        href="#"
                        className="text-danger font-weight-bold font-size-h6"
                        onClick={() => setTableAdmin("Course")}
                      >
                        {/* Total products : {Pronum ? Pronum : 0} */}
                        {/* Total Course : {numadminCourse ? numadminCourse : 0} */}
                        Module Gesamt : {numadminCourse ? numadminCourse : 0}
                      </a>
                    </div>

                    {/* <div className="col bg-light-danger px-6 py-8 rounded-xl">
                      <span className="text-danger font-weight-bold font-size-h3 d-block my-2">
                        {superAdminData?.totalArtist}
                      </span>
                      <a
                        href="#"
                        className="text-danger font-weight-bold font-size-h6 mt-2"
                        onClick={() => setTableMasetnufacture("Orders")}
                      >
                        Your orders 
                        : {orderNum ? orderNum : 0}
                      </a>
                    </div> */}

                  </div>
                </div>

                <div className="my-5 mx-5">
                  {/* {selectTableAdmin === "Admins" && <AllAdmins />} */}

                  {selectTableAdmin === "Admins" ? (
                    <AllAdmins />
                  ) : selectTableAdmin  === "Course" ? (
                    <AllCourse />
                  ) : (
                    <></>
                  )}

                  {/* {setTableAdmin === "Admins" ? (
                    <Products />
                  )
                   : selectTableManufacture === "Orders" ? (
                    <OrdersManufacture />
                  ) : (
                    <></>
                  )} */}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {userInfo?.position === "Admin" && (
        <div className="card-header border-0 bg-danger py-1 px-1">
          <div className="card-body p-0 position-relative overflow-hidden">
            {userInfo?.position === "Admin" ? (
              <>
                <div className="card-spacer">
                  <div className="row m-5">
                    <div className="col bg-light-warning px-6 py-8 rounded-xl mr-5">
                      <span className="text-danger font-weight-bold font-size-h3 d-block my-2">
                        {subAdminData?.totalUser}
                      </span>
                      <a
                        href="#"
                        className="text-danger font-weight-bold font-size-h6"
                        onClick={() => setTableSubAdmin("SubAdmins")}
                      >
                        {/* Total User : {num ? num : 0} */}
                        Nutzer Gesamt : {num ? num : 0}
                      </a>
                    </div>

                    <div className="col bg-light-danger px-6 py-8 rounded-xl">
                      <span className="text-danger font-weight-bold font-size-h3 d-block my-2">
                        {courseData?.totalArtist}
                      </span>
                      <a
                        href="#"
                        className="text-danger font-weight-bold font-size-h6 mt-2"
                        onClick={() => setTableSubAdmin("Course")}
                      >
                        {/* Total Course : {numCourse ? numCourse : 0} */}
                        Module Gesamt: {numCourse ? numCourse : 0}

                      </a>
                    </div>

                  </div>
                </div>

                <div className="my-5 mx-5">
                  {selectTableSubAdmin === "SubAdmins" ? (
                    <AllUsers />
                  ) : selectTableCourse === "Course" ? (
                    <Courses />
                  ) : (
                    <></>
                  )}

                  {/* {setTableAdmin === "Admins" ? (
                    <Products />
                  )
                   : selectTableManufacture === "Orders" ? (
                    <OrdersManufacture />
                  ) : (
                    <></>
                  )} */}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      <div>
        <div className="honda-logo-center-alignment-page">
          {userInfo?.role === "admin" && (
            <>
              <div className="card-spacer">
                <div className="row m-5"></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
