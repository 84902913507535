import React, { useEffect, useState } from "react";
import { ApiGet, ApiDelete, ApiPost } from "../../../helpers/API/ApiData";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import BellImage from "../../layout/components/Logos/bell.png";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import Select from "react-select";
// import { MultiSelect } from "react-multi-select-component";

const Notification = () => {
  let userInfo = getUserInfo();
  const [notificationsData, setNotificationData] = useState([]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [show, setShow] = useState(false); //for delete noti. modal
  const [nId, setNId] = useState();
  const [addNotiForm, setAddNotiForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [inputValue, setInputValue] = useState({});
  const [names, setNames] = useState(); //user's name list
  const [selected, setSelected] = useState([]);

  ////////////////////////////////////////////////      get data      ////////////////////////////////////////////

  useEffect(() => {
    getAllNotification();
    getNames();
  }, []);

  useEffect(() => {
    if (userInfo?.position === "Team Leader") {
      document.title = " Akademie | Teamleiter";
    } else {
      document.title = " Akademie | Admin";
    }
  }, []);

  const handleClose = () => {
    setShow(false);
  };
  const handleAddInvoice = () => {
    setAddNotiForm(false);
    setErrors({});
    setInputValue({});
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
    console.log("inputvalue", inputValue);
  };

  const getAllNotification = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`notification/get-notification?id=${userInfo?._id}`)
      .then((res) => {
        console.log("noti res", res);
        setIsLoaderVisible(false);
        setNotificationData(res?.data?.payload?.notification);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const Validation = () => {
    let error = {};
    let Formvalid = true;
    if (!inputValue.title) {
      Formvalid = false;
      error["title"] = "Der Titel ist erforderlich";
    }
    if (!inputValue.description) {
      Formvalid = false;
      error["description"] = "Beschreibung ist erforderlich ";
    }
    if (selected.length === 0) {
      Formvalid = false;
      error["to"] = "Dies ist erforderlich";
    }
    setErrors(error);
    return Formvalid;
  };

  const SendMessage = async (e) => {
    inputValue.addedBy = userInfo?._id;
    inputValue.to = selected?.map((data) => {
      return data?.value;
    });
    if (Validation()) {
      setLoading(true);
      await ApiPost(`notification/add-notification`, inputValue)
        .then((res) => {
          if (res?.status === 200) {
            setLoading(false);
            toast.success(res?.data?.message, {
              theme: "colored",
            });
            setAddNotiForm(false);
            getAllNotification();
            setInputValue({});
          } else {
            toast.error(res?.data?.message, { theme: "colored" });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
          toast.error(err?.response?.data?.message, { theme: "colored" });
        });
    }
  };

  const getNames = async () => {
    setLoading(true);
    if (userInfo?.position === "Admin") {
      await ApiGet(`admin/getUserListForBoard?&aid=${userInfo?._id}`)
        .then((res) => {
          console.log("names res", res?.data?.payload?.findUsers);
          setLoading(false);
          setNames(res?.data?.payload?.findUsers);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    if (userInfo?.position === "Team Leader") {
      console.log("---panoti---");
      await ApiGet(`admin/getUserByTlid/${userInfo?._id}`)
        .then((res) => {
          console.log("TL users res", res);
          setLoading(false);
          setNames(res?.data?.payload?.user);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleDelete = async () => {
    //delete notification
    await ApiDelete(`notification/delete-notification/${nId}`)
      .then((res) => {
        handleClose();
        setNId("");
        getAllNotification();
        toast.success("Benachrichtigung gelöscht", { theme: "colored" });
      })
      .catch((err) => {
        console.log("err");
      });
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Warnung!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Möchten Sie diese Benachrichtigung sicher löschen ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={() => handleDelete()}>
            Löschen
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <div className="card p-1">
          <div className="p-2 mb-2">
            <div className="d-flex justify-content-between">
              <div className="pt-2 pl-2">
                {/* <h3>Notifications</h3> */}
                <h3>Alle Benachrichtigungen</h3>
              </div>
              <div className="cus-medium-button-style button-height">
                <button
                  className="btn btn-warning mr-2"
                  // style={{backgroundColor: "#F39200"}}
                  onClick={() => {
                    setSelected([]);
                    setAddNotiForm(true);
                    // getAllCategory();
                  }}
                >
                  {/* Send messeage */}Nachricht senden
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <Loader
                type="Puff"
                color="#334D52"
                height={28}
                width={28}
                visible={isLoaderVisible}
              />
            </div>
            <div>
              {isLoaderVisible === false && notificationsData.length === 0 && (
                <div className="d-flex justify-content-center ">
                  Keine Benachrichtigungen
                </div>
              )}
              {notificationsData?.reverse().map?.((item, index) => {
                return (
                  <div className="pl-1 pt-2" key={index}>
                    {item?.description && item?.title && item?.to?.fname ? (
                      <div className=" d-flex ju card body shadow p-2 pt-2 bg-white rounded">
                        <div className="p-3 d-flex justify-content-between h6 m-0">
                          <div className="d-flex">
                            <div className="p-2 d-flex">
                              <div className="pr-2">
                                <img src={BellImage} width="18px" />
                              </div>
                              Benachrichtigung senden an
                            </div>
                            <div className="text-primary p-2">
                              {item?.to?.fname + " " + item?.to?.lname}
                            </div>
                          </div>
                          <div
                            className="p-2 d-flex-end cursor-pointer"
                            onClick={(e) => {
                              setNId(item?._id);
                              setShow(true);
                            }}
                          >
                            <Tooltip title="löschen" arrow>
                              <DeleteIcon />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className=" d-flex ju card body shadow p-2 pt-2 bg-white rounded">
                          <div className="p-3 d-flex justify-content-between h6 m-0">
                            <div className="d-flex">
                              <div className="p-2 d-flex">
                                <div className="pr-2">
                                  <img src={BellImage} width="18px" />
                                </div>
                                Sie haben eine neue hinzugefügt
                              </div>
                              <div className="text-primary p-2">
                                {item?.type}
                              </div>
                            </div>
                            <div
                              className="p-2 d-flex-end cursor-pointer"
                              onClick={(e) => {
                                setNId(item?._id);
                                setShow(true);
                                console.log("delete id", item?._id);
                              }}
                            >
                              <Tooltip title="löschen" arrow>
                                <DeleteIcon />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/*--------------------------------Add notification modal-----------------------*/}
      {addNotiForm ? (
        <Dialog fullScreen open={addNotiForm} onClose={handleAddInvoice}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleAddInvoice}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {addNotiForm === true ? (
              <div className="form ml-30 ">
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Title*/}Titel
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        placeholder="Titel"
                        className={`form-control form-control-lg form-control-solid `}
                        id="title"
                        name="title"
                        value={inputValue.title}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["title"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Description */}Beschreibung
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <textarea
                        placeholder="Bezeichnung"
                        className={`form-control form-control-lg form-control-solid `}
                        id="description"
                        name="description"
                        value={inputValue.description}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["description"]}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Senden an
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <Select
                        options={names?.map((e, key) => {
                          return {
                            label: e?.fname + " " + e?.lname,
                            value: e?._id,
                          };
                        })}
                        value={selected}
                        name="to"
                        isMulti
                        onChange={(e) => setSelected(e)}
                      />

                      {/* <MultiSelect
                        options={names?.map((e, key) => {
                          return {
                            label: e?.fname + " " + e?.lname,
                            value: e?._id,
                          };
                        })}
                        value={selected}
                        name="partyId"
                        onChange={(e) => setSelected(e)}
                      /> */}
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["to"]}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-warning mr-2"
                    onClick={SendMessage}
                  >
                    {/* <span>Send Messege</span> */}
                    <span>Senden</span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}
    </>
  );
};

export default Notification;
