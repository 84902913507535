import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import DataTable from "react-data-table-component";
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiDelete,
} from "../../../helpers/API/ApiData";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { useHistory } from "react-router-dom";
import { any, string } from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { customStyles } from "../Courses/TableStyle";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const AllCourse = () => {
  let userInfo = getUserInfo();
  const history = useHistory();
  const [addCourse, setAddCourse] = useState<any>(); //open add course form
  const [inputValue, setInputValue] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [isEditApi, setIsEditApi] = useState<any>(false);
  const [idForEditType, setIdForEditType] = useState<any>(); //set id for edit course
  const [courseData, setCourseData] = useState<any>();
  const [show, setShow] = useState<any>(false); //delete lesson modal
  const [uploadFiles, setUploadImages] = useState<any>(); //for upload image
  const [uploadVideo, setUploadVideo] = useState<any>();
  const [getImage, setGetImage] = useState<any>();
  const [getVideo, setGetVideo] = useState<any>();
  const [statusDisplay, setStatusDisplay] = useState<any>(false);
  const [showStatus, setShowStatus] = useState<any>(false); //for active-deactive status modal
  const [courseId, setCourseId] = useState<any>("");
  const [LessonModal, setLessonModal] = useState<any>(); //open lesson modal when click on visibility icon
  const [lessonData, setLessonData] = useState<any>();
  const [isAddLesson, setIsAddLesson] = useState<any>(); //open add course form
  const [Showmodel, setShowModel] = useState<any>();


  const showmodel = (row: any) => {
    setShowModel(true);
  };

  const handleClose = (row: any) => {
    setShowModel(false);
  };

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleCloseShowStatus = () => {
    setShowStatus(false);
    setShow(false);
  };

  const handleLessonModalClose = () => {
    setLessonModal(false);
    // setLessonData([])
    // seIdForChildCat();
  };

  const handleAddInvoice = () => {
    setAddCourse(false);
    setIsEditApi(false);
    setIsAddLesson(false);
    setErrors({});
    setInputValue({});
    setGetImage("");
    setUploadImages("");
    setGetVideo("");
    setUploadVideo("");
  };

  const onLesseonInvoice = (row: any) => {
    setLessonModal(true);
  };

  useEffect(() => {
    getAllCourses();
    //getAllCategory();
  }, []);

  const getAllCourses = async () => {
    setLoading(true)
    await ApiGet(`course/get-all-course`)
      .then((res: any) => {
        setLoading(false)
        console.log("get courses", res);
        setCourseData(res?.data?.payload?.courses);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAllLessons = async (cId: any) => {
    setCourseId(cId);
    setLoading(true)
    await ApiGet(`lesson/get-lesson?cid=${cId}&isActive=true`)
      .then((res: any) => {
        setLoading(false)
        console.log("get lesson", res);
        if (res?.data?.result === 0) {
          setLessonData(res?.data?.payload?.lesson);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const handleStatusModal = (row: any) => {
    setStatusDisplay(row.isActive);
    setIdForEditType(row._id);
    setShowStatus(true);
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row: any, index: any) => index + 1,
      width: "65px",
    },
    {
      // name: "Name",
      name: "Titel",
      selector: (row: any) => row?.name,
      sortable: true,
    },
    {
      // name: "Duration",
      name: "Dauer",
      selector: (row: any) => row?.duration,
      sortable: true,
    },
    {
      // name: "Description",
      name: "Beschreibung",
      selector: (row: any) => row?.description,
      sortable: true,
    },
    {
      // name: "Actions",
      name: "Einstellungen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  console.log("show more", row);
                  // setAdminid(row?._id);
                  showmodel(row);
                  getAllLessons(row?._id)
                }}
              >
                <Tooltip title="Zeigen Lektion" arrow>
                  <InfoOutlined />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "180px",
    },
  ];

  const columns1 = [
    {
      name: "SNr.",
      cell: (row: any, index: any) => index + 1,
      width: "65px",
    },
    {
      // name: "Name",
      name: "Titel",
      selector: (row: any) => row?.name,
      sortable: true,
    },
    {
      name: "Beschreibung",
      selector: (row: any) => (!row?.description ? "-" : row?.description),
      sortable: true,
    },

  ];

  return (
    <>
      <ToastContainer />
      <div>
        <div className="card p-1">
          <div className="p-2 mb-2">
            <div className="d-flex justify-content-between pt-2">
              {/* <h3 className="pt-2 pl-2">All Courses</h3> */}
              <h3 className="pt-2 pl-2">Alle Module</h3>

            </div>
            <DataTable columns={columns} data={courseData} pagination
              progressPending={loading}
              progressComponent={
                <Loader type="Puff" color="#334D52" height={30} width={30} />
              } />
          </div>
        </div>
      </div>

      {Showmodel ? (
        <Dialog fullScreen open={Showmodel} onClose={handleClose}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {Showmodel === true ? (
              <div className="card p-1">
                <div className="p-2 mb-2">
                  <div className="d-flex justify-content-between mb-2">
                    <div className="col-md-3 col-sm-12 d-flex justify-content-between">
                      <h2 className="pl-3 pt-2">Alle Lektion</h2>
                    </div>
                  </div>

                  <DataTable
                    columns={columns1}
                    data={lessonData}
                    customStyles={customStyles}
                    style={{
                      marginTop: "-3rem",
                    }}
                    highlightOnHover
                    pagination
                  />
                </div>
              </div>
            ) : null}


          </List>
        </Dialog>
      ) : null}
    </>
  );
};

export default AllCourse;
