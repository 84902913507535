import React, { useCallback,useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Cropper from "react-easy-crop";
import "bootstrap/dist/css/bootstrap.min.css";
// import userdata from "./tempData.json";

import DataTable from "react-data-table-component";
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiDelete,
} from "../../../helpers/API/ApiData";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { List } from "@material-ui/core";
import { Toolbar } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { getUserInfo } from "../../../utils/user.util";
import { useHistory } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LessionPdfs from "./LessonPdfs";
import getCroppedImg from "./cropimage";
import ImgDialog from "./imgDialog";

const Courses = () => {
  let userInfo = getUserInfo();
  const history = useHistory();
  const [addCourse, setAddCourse] = useState<any>(); //open add course form
  const [inputValue, setInputValue] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);
  const [inputValueForAdd, setInputValueForAdd] = useState<any>();
  const [isEditApi, setIsEditApi] = useState<any>(false);
  const [idForEditType, setIdForEditType] = useState<any>(); //set id for edit course
  const [idForDeletelesson, setIdForDeleteLesson] = useState<any>(); //active-deactive lesson
  const [showlesson, setShowLesson] = useState<any>(); //active deactive lesson modal
  const [courseData, setCourseData] = useState<any>();
  const [uploadFiles, setUploadImages] = useState<any>(); //for upload image
  const [uploadVideo, setUploadVideo] = useState<any>();
  const [getImage, setGetImage] = useState<any>();
  const [getVideo, setGetVideo] = useState<any>();
  const [statusDisplay, setStatusDisplay] = useState<any>(false);
  const [showStatus, setShowStatus] = useState<any>(false); //for active-deactive status modal for course
  const [courseId, setCourseId] = useState<any>("");
  const [courseName, setCourseName] = useState<any>();
  const [courseCategory, setCourseCategory] = useState<any>();
  const [idForEditLesson, setIdForEditLesson] = useState<any>();
  const [LessonModal, setLessonModal] = useState<any>(); //open lesson modal when click on visibility icon
  const [lessonData, setLessonData] = useState<any>();
  const [isAddLesson, setIsAddLesson] = useState<any>(); //open add course form
  const [pdfModal, setPdfModal] = useState<any>(false); //pdf CRUD modal
  const [lessonName, setLessonName] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [isLoaderVisible, setIsLoaderVisible] = useState<any>(false);
  const [page, setPage] = useState<any>(1);
  const [countPerPage, setCountPerPage] = useState<any>(10);
  const [count, setCount] = useState<any>(0);
  const [filterCourseData, setFilterCourseData] = useState<any>();
  const [users, setUsers] = useState<any>();
  const [users1, setUsers1] = useState<any>();
  const [categoryId, setCategoryId] = useState<any>();
  const [crop, setCrop] = useState<any>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<any>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>();
  const [BlobImageFile, setBlobImageFile] = useState<any>(null);
  const [ImageCropData, setImageCrop] = useState(false);
  const [rotation, setRotation] = useState(0);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  console.log("@@@@@@@@@@@@@@@", onCropComplete);

  const onClose = useCallback(() => {
    // setCroppedImage(null);
    setImageCrop(false);
    console.log("---------", croppedImage);
  }, []);

  console.log("Gettttttttimg", getImage);
  console.log("vvvvvvvvvvvvvvvv", croppedImage);
  const showCroppedImage = useCallback(async () => {
    console.log("setprevieewimg");
    setImageCrop(true);
    try {
      const croppedImage = await getCroppedImg(
        URL.createObjectURL(uploadFiles),
        croppedAreaPixels,
        rotation
      );
      // console.log("donee", { croppedImage });
      setBlobImageFile(croppedImage);
      setCroppedImage(URL.createObjectURL(croppedImage));
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);
  const handleDragEnd = async (e: any) => {
    if (!e.destination) return;
    console.log("aaaaaaa", e.destination);

    let tempData = Array.from(lessonData);
    console.log("bbbbbbb", tempData);

    let [source_data] = tempData.splice(e.source.index, 1);
    console.log("ccccccccccc", e.source.index);
    tempData.splice(e.destination.index, 0, source_data);
    console.log("dddddddd", e.destination.index);
    setUsers(lessonData);

    // getAllCourses();

    console.log(" e.source.draggableId", e.destination.draggableId);
    let CourseData = {
      cid: courseId,
      lid: e.draggableId,
      oldPosition: e.source.index + 1,
      newPosition: e.destination.index + 1,
    };

    // CourseData.append("name", e.source.index);
    // CourseData.append("description",e.destination.index);

    setLoading(true);
    await ApiPut(`lesson/update-lessonNumber`, CourseData)
      .then((res: any) => {
        setLoading(false);
        if (res?.status === 200) {
          toast.success("Daten erfolgreich bearbeiten", { theme: "colored" });
          console.log("editAdmin", res);
          setAddCourse(false);
          getAllCourses();
          getAllLessons(courseId);
          setInputValue({});
          setInputValueForAdd({});
          setIsEditApi(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };
  const handleDragEnd1 = async (e: any) => {
    if (!e.destination) return;
    console.log("aaaaaaa", e.destination);

    let tempData = Array.from(courseData);
    console.log("bbbbbbb", tempData);

    let [source_data] = tempData.splice(e.source.index, 1);
    console.log("ccccccccccc", e.source.index);
    tempData.splice(e.destination.index, 0, source_data);
    console.log("dddddddd", e.destination.index);

    setUsers1(courseData);

    // getAllCourses();

    console.log(" e.source.draggableId1111", e.draggableId.split(",")[0]);
    let CourseData = {
      cid: e.draggableId.split(",")[1],
      aid: userInfo?._id,
      course_id: e.draggableId.split(",")[0],
      oldPosition: e.source.index + 1,
      newPosition: e.destination.index + 1,
    };

    // CourseData.append("name", e.source.index);
    // CourseData.append("description",e.destination.index);

    setLoading(true);
    await ApiPut(`course/update-courseNumber`, CourseData)
      .then((res: any) => {
        setLoading(false);
        if (res?.status === 200) {
          toast.success("Daten erfolgreich bearbeiten", { theme: "colored" });
          console.log("editAdmin", res);
          setAddCourse(false);
          getAllCourses();
          setInputValue({});
          setInputValueForAdd({});
          setIsEditApi(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };
  const Validation = () => {
    let error: any = {};
    let Formvalid = true;
    if (!inputValue.name) {
      Formvalid = false;
      error["name"] = "Module name is required";
    }
    if (!inputValue.description) {
      Formvalid = false;
      error["description"] = "description is required";
    }
    if (!inputValue.duration) {
      Formvalid = false;
      error["duration"] = "duration is required";
    }
    // if(!inputValue.image)
    // {
    //     Formvalid=false;
    //     error["image"]="Please Choose image"
    // }
    setErrors(error);
    return Formvalid;
  };

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "name" && value.trim() === "") return;
    if (name === "description" && value.trim() === "") return;
    if (name === "duration" && value.trim() === "") return;
    else {
      setInputValue({ ...inputValue, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
    console.log("inputValue", inputValue);
  };

  const handleCloseShowStatus = () => {
    setShowStatus(false);
  };
  const handlcloselessonModal = () => {
    setShowLesson(false);
  };

  //pdf modal
  const closePdfModal = () => {
    setPdfModal(false);
    setLessonName("");
    setIdForEditLesson("");
  };

  const handleLessonModalClose = () => {
    setLessonModal(false);
  };

  const handleAddInvoice = () => {
    setAddCourse(false);
    setIsEditApi(false);
    setIsAddLesson(false);
    setErrors({});
    setInputValue({});
    setGetImage("");
    setUploadImages("");
    setGetVideo("");
    setUploadVideo("");
  };

  const onLesseonInvoice = (row: any) => {
    setLessonModal(true);
  };

  useEffect(() => {
    getAllCourses();
  }, [page, countPerPage]);

  useEffect(() => {
    getAllCategory();
  }, []);

  console.log("filterCourseData", filterCourseData);
  const handleSearchData = (e: any) => {
    console.log("first", e.target.value);
    var value = e.target.value.toLowerCase();
    setCourseData(() =>
      filterCourseData?.filter((item: any) =>
        item?.name?.toLowerCase().includes(value)
      )
    );
  };

  const getAllCourses = async () => {
    setLoading(true);
    // if(!search){
    await ApiGet(`course/get-all-course?aid=${userInfo?._id}`)
      .then((res: any) => {
        setLoading(false);
        console.log(
          "get courses",
          res?.data?.payload?.courses[0]?.category?._id
        );
        console.log("get courfdfdfses", res?.data?.payload?.courses);
        setCourseData(res?.data?.payload?.courses);
        setCategoryId(res?.data?.payload?.courses?.category?._id);

        setFilterCourseData(res?.data?.payload?.courses);
      })
      .catch((err) => {
        console.log("err", err);
      });
    // }else{
    //   await ApiGet(`course/get-all-course?aid=${userInfo?._id}&search=${search}`)
    //     .then((res: any) => {
    //       setLoading(false);
    //       console.log("get courses", res?.data?.payload?.courses);
    //       setCourseData(res?.data?.payload?.courses);
    //       setFilterCourseData(res?.data?.payload?.courses)
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // }
  };

  const getAllCategory = async () => {
    await ApiGet(`category/get-all-category?aid=${userInfo?._id}&isActive=true`)
      .then((res: any) => {
        if (res?.data?.result === 0) {
          console.log("get category", res?.data?.payload?.category);
          setCourseCategory(res?.data?.payload?.category);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAllLessons = async (cId: any) => {
    setCourseId(cId);
    setLoading(true);
    await ApiGet(`lesson/get-lesson?cid=${cId}`)
      .then((res: any) => {
        setLoading(false);
        console.log("get lesson", res);
        if (res?.data?.result === 0) {
          setLessonData(res?.data?.payload?.lesson);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const AddCourse = async (e: any) => {
    let CourseData = new FormData();
    CourseData.append("aid", userInfo?._id);
    CourseData.append("cid", inputValue?.cid);
    CourseData.append("name", inputValue.name);
    CourseData.append("description", inputValue.description);
    CourseData.append("duration", inputValue.duration);
    CourseData.append("image", BlobImageFile);
    if (Validation()) {
      setLoading(true);
      await ApiPost(`course/add-course`, CourseData)
        .then((res: any) => {
          console.log("add course", res);
          if (res?.status === 200) {
            setLoading(false);
            toast.success("Module erfolgreich hinzugefügt", {
              theme: "colored",
            });
            setAddCourse(false);
            getAllCourses();
            setInputValue({});
            setInputValueForAdd({});
          } else {
            toast.error(res?.data?.message, { theme: "colored" });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
          toast.error(err?.response?.data?.message, { theme: "colored" });
        });
    }
  };

  const AddLesson = async (e: any) => {
    let LessonData = new FormData();
    // LessonData.append("cid", inputValue.cid);
    LessonData.append("cid", courseId);
    LessonData.append("name", inputValue.name);
    LessonData.append("description", inputValue.description);
    LessonData.append("video", uploadVideo);

    setLoading(true);
    await ApiPost(`lesson/add-lesson`, LessonData)
      .then((res: any) => {
        setLoading(false);
        console.log("add course", res);
        if (res?.status === 200) {
          toast.success("Lektion erfolgreich hinzugefügt", {
            theme: "colored",
          });
          setInputValue({});
          setIsAddLesson(false);
          getAllLessons(courseId);
          setInputValueForAdd({});
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const EditCourse = async (e: any) => {
    let CourseData = new FormData();
    CourseData.append("aid", userInfo?._id);
    CourseData.append("cid", inputValue.cid);
    CourseData.append("name", inputValue.name);
    CourseData.append("description", inputValue.description);
    CourseData.append("duration", inputValue.duration);
    CourseData.append("image", !BlobImageFile ? getImage : BlobImageFile);

    setLoading(true);
    await ApiPut(`course/update-course/${idForEditType}`, CourseData)
      .then((res: any) => {
        setLoading(false);
        if (res?.status === 200) {
          toast.success("Module erfolgreich bearbeiten", { theme: "colored" });
          console.log("editAdmin", res);
          setAddCourse(false);
          getAllCourses();
          getAllLessons(courseId);
          setInputValue({});
          setInputValueForAdd({});
          setIsEditApi(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const EditLesson = async (e: any) => {
    let LessonData = new FormData();
    LessonData.append("cid", courseId);
    LessonData.append("name", inputValue.name);
    LessonData.append("description", inputValue.description);
    LessonData.append("video", !uploadVideo ? getVideo : uploadVideo);

    setLoading(true);
    await ApiPut(`lesson/update-lesson/${idForEditLesson}`, LessonData)
      .then((res: any) => {
        setLoading(false);
        if (res?.status === 200) {
          toast.success("Lektion erfolgreich bearbeiten", { theme: "colored" });
          console.log("editAdmin", res);
          setIsAddLesson(false);
          getAllLessons(courseId);
          setInputValue({});
          setInputValueForAdd({});
          setIsEditApi(false);
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const handleStatusModal = (row: any) => {
    setStatusDisplay(row.isActive);
    setIdForEditType(row._id);
    setIdForEditLesson(row?._id);
    setShowStatus(true);
  };

  const handledeleteLessonModal = (row: any) => {
    setShowLesson(true);
    setIdForDeleteLesson(row?._id);
    setStatusDisplay(row.isActive);
  };

  const handleDeletCourse = (status: any) => {
    //update active-deactive status
    ApiDelete(`course/delete-course/${idForEditType}?isActive=${status}`)
      .then((res: any) => {
        console.log("status res", res);
        if (res?.status === 200) {
          setShowStatus(false);
          toast.success("Module erfolgreich aktualisiert", {
            theme: "colored",
          });
          setInputValue({});
          getAllCourses();
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };

  const handleDeletLesson = (status: any) => {
    ApiDelete(`lesson/delete-lesson/${idForDeletelesson}?isActive=${status}`) //lesson active deactive status
      .then((res: any) => {
        console.log("status res for lesson", res);
        if (res?.status === 200) {
          setShowLesson(false);
          toast.success("Lektion erfolgreich löschen", { theme: "colored" });
          setInputValue({});
          getAllLessons(courseId); //courseId
        } else {
          toast.error(res?.data?.message, { theme: "colored" });
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.response?.data?.message, { theme: "colored" });
      });
  };
  const debouncedSearchTerm = useDebounce(search, 500);

  // Hook
  function useDebounce(value: any, delay: any) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsLoaderVisible(true);
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCourses();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllCourses();
    }
  }, [debouncedSearchTerm]);

  const columns = [
    {
      name: "SNr.",
      cell: (row: any, index: any) => index + 1,
      width: "65px",
    },
    {
      name: "Titel",
      selector: (row: any) => row?.name,
      sortable: true,
    },
    {
      name: "Dauer",
      selector: (row: any) => row?.duration,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row: any) => {
        return (
          <>
            <div
              className="cursor-pointer"
              onClick={() => {
                handleStatusModal(row);
              }}
            >
              <Tooltip title="Statuseigenschaft" arrow>
                <span className="badge badge-secondary w-100">
                  {/* {row?.isActive === true ? "Active" : "Deactive"} */}
                  {row?.isActive === true ? "Aktiv" : "Deaktiv"}
                </span>
              </Tooltip>
            </div>
          </>
        );
      },
      sortable: true,
    },

    {
      // name: "Lessons",
      name: "Lektionen",
      width: "200px",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  // history.push(`/courses/${row?._id}`);
                  setCourseId(row._id);
                  setCourseName(row?.name);
                  onLesseonInvoice(row);
                  getAllLessons(row._id);
                  console.log("on visibility", row._id);
                }}
              >

                <div className="cursor-pointer">
                  <button className="bg-warning btn btn-warning ml-2">
                    Lektion
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      },
    },

    {
      name: "Einstellungen",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  setAddCourse(true);
                  console.log("rows", row);
                  setIsEditApi(true);
                  setIdForEditType(row?._id);
                  getAllCourses();
                  setInputValue({
                    name: row?.name,
                    cid: row?.cid,
                    description: row?.description,
                    duration: row?.duration,
                    image: setGetImage(row?.image),
                  });
                }}
              >
                <Tooltip title="Bearbeiten Module" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>
            </div>
            <div className="cursor-pointer">
              <button
                className="bg-warning btn btn-warning ml-2"
                onClick={() => history.push(`/questionSet/${row?._id}`)}
              >
                {/* Question Set */}Lernüberprüfung
              </button>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <>
      <ToastContainer />
      <div className="App mt-4"></div>
      <div className="card p-1">
        <div className="p-2 mb-2">
          {/* <div className="d-flex justify-content-between pt-2"> */}
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              {/* <h2 className="pl-3 pt-2">Courses</h2> */}
              <h2 className="pl-3 pt-2">Module</h2>
            </div>
            <div className="col">
              <div>

                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="title"
                  placeholder="Suchen Module..."
                  onChange={(e) => handleSearchData(e)}
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-warning mr-2"
                onClick={() => {
                  setAddCourse(true);
                }}
              >
                {/* Add Course */}
                Modul hinzufügen
              </button>
            </div>
          </div>

          {/* active-deactive model for course */}
          <Modal show={showStatus} onHide={handleCloseShowStatus}>
            <Modal.Header closeButton>
              <Modal.Title className="text-danger">
                {/*Warnung!*/}Warnung!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Module {statusDisplay === true ? "Deaktivieren" : "Aktivieren"} ?
              {/* {statusDisplay === true ? "Deactive" : "Active"} this Module ! */}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseShowStatus}>
                {/* cancel */}Abbrechen
              </Button>
              <Button
                variant="danger"
                onClick={(e: any) => {
                  handleDeletCourse(!statusDisplay);
                }}
              >
                {/* {statusDisplay === true ? "Deactive" : "Active"} */}
                {statusDisplay === true ? "Deaktivieren" : "Aktivieren"}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* end active-deactive model for course*/}

          {courseData?.length !== 0 ? (
            <DragDropContext onDragEnd={handleDragEnd1}>
              <table className="table borderd">
                <thead>
                  <tr>
                    <th />
                    {/* <th>SNr.</th> */}
                    <th>Title</th>
                    <th>Dauer</th>
                    <th>Kategorie</th>
                    <th>Status</th>
                    <th>Lektionen</th>
                    <th>Einstellungen</th>

                  </tr>
                </thead>
                <Droppable droppableId="droppable-1">
                  {(provider) => (
                    <tbody
                      className="text-capitalize"
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                    >
                      {courseData?.map((row: any, index: any) => (
                        <Draggable
                          key={row.name}
                          draggableId={row?._id + "," + row.cid}
                          index={index}
                        >
                          {(provider) => (
                            <tr
                              {...provider.draggableProps}
                              ref={provider.innerRef}
                            >
                              <td {...provider.dragHandleProps}> = </td>
                              {/* <td>{index + 1}</td> */}
                              <td>{row?.name}</td>
                              <td>{row?.duration}</td>
                              <td>{row?.category[0]?.name}</td>
                              <td>
                                {" "}
                                <>
                                  {
                                    <>
                                      <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                          handleStatusModal(row);
                                        }}
                                      >
                                        <Tooltip
                                          title="Statuseigenschaft"
                                          arrow
                                        >
                                          <span className="badge badge-secondary w-100">
                                            {/* {row?.isActive === true ? "Active" : "Deactive"} */}
                                            {row?.isActive === true
                                              ? "Aktiv"
                                              : "Deaktiv"}
                                          </span>
                                        </Tooltip>
                                      </div>
                                    </>
                                  }
                                </>
                              </td>
                              <td>
                                <>
                                  {
                                    <>
                                      <div className="d-flex justify-content-between">
                                        <div
                                          className="cursor-pointer pl-2"
                                          onClick={() => {
                                            // history.push(`/courses/${row?._id}`);
                                            setCourseId(row._id);
                                            setCourseName(row?.name);
                                            onLesseonInvoice(row);
                                            getAllLessons(row._id);
                                            console.log(
                                              "on visibility",
                                              row._id
                                            );
                                          }}
                                        >

                                          <div className="cursor-pointer">
                                            <button className="bg-warning btn btn-warning ml-2">
                                              Lektion
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  }
                                </>
                              </td>
                              <td>
                                {" "}
                                <>
                                  {
                                    // name: "Lessons",
                                    <>
                                      {
                                        <>
                                          <div className="d-flex justify-content-between">
                                            <div
                                              className="cursor-pointer pl-2"
                                              onClick={() => {
                                                setAddCourse(true);
                                                console.log("rows", row);
                                                setIsEditApi(true);
                                                setIdForEditType(row?._id);
                                                getAllCourses();
                                                setInputValue({
                                                  name: row?.name,
                                                  cid: row?.cid,
                                                  description: row?.description,
                                                  duration: row?.duration,
                                                  image: setGetImage(
                                                    row?.image
                                                  ),
                                                });
                                              }}
                                            >
                                              <Tooltip
                                                title="Bearbeiten Module"
                                                arrow
                                              >
                                                <CreateIcon />
                                              </Tooltip>
                                            </div>
                                          </div>
                                          <div className="cursor-pointer">
                                            <button
                                              className="bg-warning btn btn-warning ml-2"
                                              onClick={() =>
                                                history.push(
                                                  `/questionSet/${row?._id}`
                                                )
                                              }
                                            >
                                              {/* Question Set */}Lernüberprüfung
                                            </button>
                                          </div>
                                        </>
                                      }
                                    </>
                                  }
                                </>
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provider.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </table>
            </DragDropContext>
          ) : (
            <h4 style={{ textAlign: "center" }}>Keine Daten gefunden</h4>
          )}
          {/* <DataTable
            columns={columns}
            data={courseData}
            progressPending={loading}
            progressComponent={
              <Loader type="Puff" color="#334D52" height={30} width={30} />
            }
            pagination
          /> */}
        </div>
      </div>

      {LessonModal ? (
        <Dialog fullScreen open={LessonModal} onClose={handleLessonModalClose}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleLessonModalClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {LessonModal === true ? (
              <div className="card p-1">
                <div className="p-2 mb-2">
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <h2 className="pl-3 pt-2">{courseName} | Alle Lektion</h2>
                    </div>
                    <div>
                      <button
                        className="btn btn-warning mr-2"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => {
                          setIsAddLesson(true);
                          // setInputValueForAdd({ parentCategoryId: idForChildCat })
                        }}
                      >
                        {/* Add Lesson */}
                        Hinzufügen Lektion
                      </button>
                    </div>
                  </div>

                  {/* active-deactive model for lesson  */}
                  <Modal show={showlesson} onHide={handlcloselessonModal}>
                    <Modal.Header closeButton>
                      <Modal.Title className="text-danger">
                        {/*Warnung!*/}Warnung!
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      Lektion{" "}
                      {/* {statusDisplay === true ? "Deactive" : "Active"} this Lesson ! */}
                      {statusDisplay === true ? "Deaktivieren" : "Aktivieren"}?
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handlcloselessonModal}
                      >
                        {/* cancel */}Abbrechen
                      </Button>
                      <Button
                        variant="danger"
                        onClick={(e: any) => {
                          handleDeletLesson(!statusDisplay);
                        }}
                      >
                        {/* {statusDisplay === true ? "Deactive" : "Active"} */}
                        {statusDisplay === true ? "Deaktivieren" : "Aktivieren"}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* end active-deactive model for lesson */}

                  {lessonData?.length !== 0 ? (
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <table className="table borderd">
                        <thead>
                          <tr>
                            <th />
                            {/* <th>SNr.</th> */}
                            <th>Name</th>
                            <th>Beschreibung</th>
                            <th>Status</th>
                            <th>Einstellungen</th>
                            {/* <th>Gender</th>
              <th>Gender</th> */}
                          </tr>
                        </thead>
                        <Droppable droppableId="droppable-1">
                          {(provider) => (
                            <tbody
                              className="text-capitalize"
                              ref={provider.innerRef}
                              {...provider.droppableProps}
                            >
                              {lessonData?.map((row: any, index: any) => (
                                <Draggable
                                  key={row.name}
                                  draggableId={row._id}
                                  index={index}
                                >
                                  {(provider) => (
                                    <tr
                                      {...provider.draggableProps}
                                      ref={provider.innerRef}
                                    >
                                      <td {...provider.dragHandleProps}> = </td>
                                      {/* <td>{index + 1}</td> */}
                                      <td>{row.name}</td>
                                      <td>{row.description}</td>

                                      <td>
                                        {" "}
                                        <>
                                          <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                              handledeleteLessonModal(row);
                                            }}
                                          >
                                            <Tooltip
                                              title="Status Property"
                                              arrow
                                            >
                                              <span className="badge badge-secondary w-100">
                                                {/* {row?.isActive === true ? "Active" : "Deactive"} */}
                                                {row?.isActive === true
                                                  ? "Aktiv"
                                                  : "Deaktiv"}
                                              </span>
                                            </Tooltip>
                                          </div>
                                        </>
                                      </td>
                                      <td>
                                        {" "}
                                        <>
                                          <div className="d-flex mr-2">
                                            <div
                                              className="cursor-pointer pl-2"
                                              onClick={() => {
                                                setIsAddLesson(true);
                                                console.log("rows lesson", row);
                                                setIsEditApi(true);
                                                setIdForEditLesson(row?._id);
                                                getAllLessons(courseId);
                                                setInputValue({
                                                  name: row?.name,
                                                  description: row?.description,
                                                  video: setGetVideo(
                                                    row?.video
                                                  ),
                                                });
                                              }}
                                            >
                                              <Tooltip title="Bearbeiten" arrow>
                                                <CreateIcon />
                                              </Tooltip>
                                            </div>
                                            <div
                                              className="cursor-pointer pl-2"
                                              onClick={() => {
                                                setPdfModal(true);
                                                setIdForEditLesson(row?._id);
                                                setLessonName(row?.name);
                                              }}
                                            >
                                              <Tooltip title="pdFs" arrow>
                                                <PictureAsPdfSharpIcon />
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provider.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>
                  ) : (
                    <h4 style={{ textAlign: "center" }}>
                      Keine Daten gefunden
                    </h4>
                  )}
                </div>
                {/* pdf modal
                 */}
                {pdfModal && (
                  <LessionPdfs
                    pdfModal={pdfModal}
                    closePdfModal={closePdfModal}
                    idForEditLesson={idForEditLesson}
                    courseId={courseId}
                    lessonName={lessonName}
                  />
                )}
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}

      {isAddLesson ? (
        <Dialog fullScreen open={isAddLesson} onClose={handleAddInvoice}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleAddInvoice}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            {isAddLesson === true ? (
              <div className="form ml-30 ">
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Lesson Name */}Lektion Name
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <input
                        type="text"
                        className={`form-control form-control-lg form-control-solid `}
                        id="name"
                        name="name"
                        value={inputValue.name}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["name"]}
                    </span>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    {/* Description */}Beschreibung
                  </label>
                  <div className="col-lg-9 col-xl-6">
                    <div>
                      <textarea
                        className={`form-control form-control-lg form-control-solid `}
                        id="description"
                        name="description"
                        value={inputValue.description}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        top: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {errors["description"]}
                    </span>
                  </div>
                </div>

                {isEditApi ? (
                  <>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Video aktualisieren
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <div>
                          <input
                            type="file"
                            accept="video/*"
                            className={`form-control form-control-lg form-control-solid `}
                            id="video"
                            name="video"
                            onChange={(e: any) => {
                              setUploadVideo(e.target.files[0]);
                            }}
                          />
                          <br />
                          {uploadVideo ? (
                            <video
                              controls
                              src={URL.createObjectURL(uploadVideo)}
                              style={{ width: "400px", height: "200px" }}
                            />
                          ) : (
                            <video
                              controls
                              src={getVideo}
                              style={{ width: "400px", height: "200px" }}
                            />
                          )}
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["video"]}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Video hinzufügen
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <div>
                          <input
                            type="file"
                            accept="video/*"
                            className={`form-control form-control-lg form-control-solid `}
                            id="video"
                            name="video"
                            onChange={(e: any) => {
                              setUploadVideo(e.target.files[0]);
                            }}
                          />
                          <br />
                          {uploadVideo && (
                            <video
                              controls
                              src={URL.createObjectURL(uploadVideo)}
                              style={{ width: "400px", height: "200px" }}
                            />
                          )}
                        </div>
                        <span
                          style={{
                            color: "red",
                            top: "5px",
                            fontSize: "12px",
                          }}
                        >
                          {errors["video"]}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="d-flex align-items-center justify-content-center">
                  {loading ? (
                    <button className="btn btn-warning mr-2">
                      <span>
                        Speichern
                      </span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning mr-2"
                      onClick={(e) => {
                        isEditApi ? EditLesson(e) : AddLesson(e);
                      }}
                    >
                      <span>
                        Speichern
                      </span>
                      {loading && (
                        <span className="mx-3 spinner spinner-white"></span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </List>
        </Dialog>
      ) : null}

      {/*modal for add course */}
      <Dialog
        fullScreen
        open={addCourse}
        onClose={handleAddInvoice}
      // TransitionComponent={Transition}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleAddInvoice}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <List>
          {addCourse === true ? (
            <div className="form ml-30 ">
              {!isEditApi && (
                <>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      {/* Course Category */}
                      Module Kategorie
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <select
                          className={`form-control form-control-lg form-control-solid `}
                          id="cid"
                          name="cid"
                          value={inputValue.cid}
                          onChange={(e) => {
                            handleOnChange(e);
                          }}
                        >
                          <option value="" disabled selected hidden>
                            Kategorie wählen
                          </option>

                          {courseCategory &&
                            courseCategory?.map((item: any) => {
                              return (
                                <option value={item?._id}>{item?.name}</option>
                              );
                            })}
                        </select>
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["cid"]}
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Module Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="name"
                      name="name"
                      value={inputValue.name}
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["name"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Beschreibung
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <textarea
                      className={`form-control form-control-lg form-control-solid `}
                      id="description"
                      name="description"
                      value={inputValue.description}
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["description"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  {/* Duration */}Dauer
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="duration"
                      name="duration"
                      value={inputValue.duration}
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["duration"]}
                  </span>
                </div>
              </div>

              {isEditApi ? (
                <>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Bild aktualisieren
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          className={`form-control form-control-lg form-control-solid `}
                          id="image"
                          name="image"
                          onChange={(e: any) => {
                            setUploadImages(e.target.files[0]);
                          }}
                        />
                        <br />
                        {uploadFiles ? (
                          <img
                            src={URL.createObjectURL(uploadFiles)}
                            style={{ width: "346px", height: "200px", borderRadius: "10px", objectFit: "cover" }}
                          />
                        ) : (
                          <img
                            src={getImage}
                            style={{ width: "346px", height: "200px", borderRadius: "10px", objectFit: "cover" }}
                          />
                        )}
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["image"]}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">
                      Bild
                    </label>
                    <div className="col-lg-9 col-xl-6">
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          className={`form-control form-control-lg form-control-solid `}
                          id="image"
                          name="image"
                          onChange={(e: any) => {
                            setUploadImages(e.target.files[0]);
                          }}
                        />
                      </div><br />
                      {/* {uploadFiles && (
                        <img
                          src={URL.createObjectURL(uploadFiles)}
                          style={{ width: "346px", height: "200px", borderRadius: "10px", objectFit: "cover" }}
                        />
                      )} */}

                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["image"]}
                      </span>
                    </div>
                  </div>
                </>
              )}

{uploadFiles && (
                <>
                  <Cropper
                    image={URL.createObjectURL(uploadFiles)}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 3}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                  <br />
                  <div>
                    <button
                      className="btn btn-warning mr-2"
                      onClick={() => showCroppedImage()}
                    >
                      Vorschau zeigen
                    </button>
                  </div>
                </>
              )}

              <div className="d-flex align-items-center justify-content-center">
                {loading ? (
                  <button className="btn btn-warning mr-2">
                    <span>
                      {/* {isEditApi ? "Bearbeiten" : "Hinzufügen"} Module */}
                      Speichern
                    </span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                ) : (
                  <button
                    className="btn btn-warning mr-2"
                    onClick={(e: any) => {
                      isEditApi ? EditCourse(e) : AddCourse(e);
                    }}
                  >
                    <span>
                      {/* {isEditApi ? "Bearbeiten Module" : "Hinzufügen Module"} */}Speichern
                    </span>
                    {loading && (
                      <span className="mx-3 spinner spinner-white"></span>
                    )}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </List>
        {ImageCropData && <ImgDialog img={croppedImage} onClose={onClose} />} 
      </Dialog>
      {/*end modal of add course */}
    </>
  );
};

export default Courses;
