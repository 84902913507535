import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import DashboardPage from "./pages/DashboardPage";
import Realestate from "../_metronic/components/RealEstate/Realestate";
import AllAdmins from "../_metronic/components/AllAdmins/AllAdmins";
import AllCourse from '../_metronic/components/AllAdmins/AllCourse';
import { getUserInfo } from "../utils/user.util";
import AllUsers from "../_metronic/components/AllUsers/AllUsers";
import Courses from "../_metronic/components/Courses/Courses";
import CourseCategory from "../_metronic/components/CourseCategory/CourseCategory";
import Question from "../_metronic/components/Question/question";
import QuestionSet from "../_metronic/components/QuestionSet/QuestionSet";
import BordName from "../_metronic/components/BordName/BordName";
import App from "../_metronic/components/Videosdk";
import Board from "../_metronic/components/Board/boards";
import CreateSession from "../_metronic/components/CreateSession/CreateSession";
import Notification from "../_metronic/components/Notification/Notification";


export default function BasePage() {
  let userInfo = getUserInfo();

  return (
    <>
      {userInfo?.position === "Super Admin" && (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <ContentRoute
              exact
              path="/dashboard"
              component={DashboardPage}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/admins"
              component={AllAdmins}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/all-courses"
              component={AllCourse}
              children={undefined}
              render={undefined}
            />

            {/* <ContentRoute exact path="/type" component={Type} children={undefined} render={undefined} /> */}
            <Redirect to="error/error-v6" />
          </Switch>
        </Suspense>
      )}

      {userInfo?.position === "Admin" && (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <ContentRoute
              exact
              path="/dashboard"
              component={DashboardPage}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/users"
              component={AllUsers}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/courses"
              component={Courses}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/course-category"
              component={CourseCategory}
              children={undefined}
              render={undefined}
            />

            <ContentRoute
              exact
              path="/question/:id"
              component={Question}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/questionSet/:id"
              component={QuestionSet}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/bord-name"
              component={BordName}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/session"
              component={App}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/create-session"
              component={CreateSession}
              children={undefined}
              render={undefined}
            />

            <ContentRoute
              exact
              path="/board/:id"
              component={Board}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/notification"
              component={Notification}
              children={undefined}
              render={undefined}
            />
            {/* <ContentRoute exact path="/lessons" component={Lessons} /> */}

            {/* <ContentRoute exact path="/courses/:id" component={Lessons} /> */}

            <Redirect to="error/error-v6" />
          </Switch>
        </Suspense>
      )}

      {userInfo?.position === "Team Leader" && (
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <ContentRoute
              exact
              path="/dashboard"
              component={DashboardPage}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/users"
              component={AllUsers}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/question/:id"
              component={Question}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/questionSet/:id"
              component={QuestionSet}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/bord-name"
              component={BordName}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/session"
              component={App}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/create-session"
              component={CreateSession}
              children={undefined}
              render={undefined}
            />
            <ContentRoute
              exact
              path="/board/:id"
              component={Board}
              children={undefined}
              render={undefined}
            />

            <ContentRoute
              exact
              path="/notification"
              component={Notification}
              children={undefined}
              render={undefined}
            />

            {/* <ContentRoute exact path="/lessons" component={Lessons} /> */}

            {/* <ContentRoute exact path="/courses/:id" component={Lessons} /> */}

            <Redirect to="error/error-v6" />
          </Switch>
        </Suspense>
      )}

    </>
  );
}
