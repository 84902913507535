import React, { useState, useEffect } from "react";
import { Dialog, Toolbar, IconButton, List, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { DropzoneDialogBase } from "material-ui-dropzone"; //pdf modal
import { ApiPost, ApiGet, ApiDelete } from "../../../helpers/API/ApiData";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { customStyles } from "../Courses/TableStyle";

const LessionPdfs = (props: any) => {
  const { closePdfModal, pdfModal, courseId, idForEditLesson, lessonName } =
    props;

  const [uploadPdf, setUploadPdf] = useState<any>(false);
  const [fileObjects, setFileObjects] = useState<any>([]);
  const [allPDFs, setAllPDFs] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [deletePDF, setDeletePDF] = useState<any>(false);
  const [deletePDFId, setDeletePDFId] = useState<any>();
  const [isType, setIsType] = useState<any>();
  const [inputValue, setInputValue] = useState<any>();
  const[ButtonValue,setButtonValue]=useState<any>();
  const[btnErr,setBtnErr]=useState<any>("");
  const [errors, setErrors] = useState<any>("");

  useEffect(() => {

    getAllPDFs();
  }, []);

  const   PdfValidation = () => {
    if (!inputValue) {
      console.log("----------");
      setErrors("PDF Name ist erforderlich");
    }
    if (!ButtonValue) {
      console.log("---------->>>>");
      setBtnErr("Taste Name ist erforderlich");
    } else{
      setUploadPdf(true);
    }
  };

  // const pdfbutttonvalidation=()=>{
  //   if (!inputValue) {
  //     console.log("----------");
  //     setErrors("Taste Name ist erforderlich");
  //   } else {
  //     setUploadPdf(true);
  //   }
  // };
  // }

  console.log("error",errors);
  const handleAddInvoice = () => {
    setIsType(false);
  };

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
    setErrors("")
    setButtonValue({...ButtonValue,[name]:value});
    setBtnErr("")
  };

  const onSubmitPdfs = async () => {
    setLoading(true);
    // setFileObjects([]);
    let data: any = new FormData();
    data.append("link", fileObjects[0].file);
    // data.append("name", fileObjects[0].file.name);
    data.append("lid", idForEditLesson);
    data.append("cid", courseId);
    data.append("name", inputValue?.name);
    data.append("buttonName", inputValue?.Buttonname);
    await ApiPost(`lessonPDF/add-lessonPDF`, data)
      .then((res: any) => {
        toast.success("Fügen Sie Lektion PDF erfolgreich hinzu");
        setFileObjects([]);
        setUploadPdf(false);
        setIsType(false);
        getAllPDFs();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setLoading(false);
      });
  };

  const getAllPDFs = async () => {
    setLoading(true);
    await ApiGet(`lessonPDF/get-lessonPDF?lid=${idForEditLesson}`)
      .then((res: any) => {
        setAllPDFs(res?.data?.payload?.lessonPDF);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setLoading(false);
      });
  };

  const dialogTitle = () => (
    <>
      <span>{/*Upload PDF*/}PDF hochladen</span>
      <IconButton
        style={{ right: "12px", top: "8px", position: "absolute" }}
        onClick={() => setUploadPdf(false)}
      >
        <CloseIcon />
      </IconButton>
    </>
  );

  const closeDeletePDFModal = () => {
    setDeletePDF(false);
  };

  const handleDeletePDF = async () => {
    setLoading(true);
    await ApiDelete(`lessonPDF/delete-lessonPDF/${deletePDFId}`)
      .then((res: any) => {
        toast.success("Pdf erfolgreich löschen");
        setDeletePDF(false);
        setDeletePDFId(undefined);
        getAllPDFs();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row: any, index: any) => index + 1,
      width: "65px",
    },
    {
      name: "Name",
      selector: (row: any) => row?.name,
      sortable: true,
    },
    {
      name: "Action",
      width: "200px",
      cell: (row: any) => {
        return (
          <>
            <div className="d-flex justify-content-between">
              <div
                className="cursor-pointer pl-2"
                onClick={() => {
                  console.log("!!", row);
                  setDeletePDFId(row?._id);
                  setDeletePDF(true);
                }}
              >
                <Tooltip title="Delete PDF" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Dialog fullScreen open={pdfModal} onClose={closePdfModal}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closePdfModal}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <List>
          <div className="card p-1">
            <div className="p-2 mb-2">
              <div className="d-flex justify-content-between mb-2">
                <div>
                  <h2 className="pl-3 pt-2">
                    {lessonName} | {/*Upload PDFs*/}PDFS hochladen
                  </h2>
                </div>
                <Button
                  className="btn-warning"
                  onClick={() => {
                    // setUploadPdf(true);
                    setIsType(true);
                  }}
                >
                  PDF hinzufügen
                </Button>
              </div>

              <Modal show={deletePDF} onHide={closeDeletePDFModal}>
                <Modal.Header closeButton>
                  <Modal.Title className="text-danger">Warnung!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie diese PDF sicher löschen? ?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeDeletePDFModal}>
                    {/* Cancel */}Abbrechen
                  </Button>
                  <Button variant="danger" onClick={() => handleDeletePDF()}>
                    {/* Delete */}Löschen
                  </Button>
                </Modal.Footer>
              </Modal>

              <DataTable
                columns={columns}
                data={allPDFs}
                customStyles={customStyles}
                style={{
                  marginTop: "-3rem",
                }}
                progressPending={loading}
                progressComponent={
                  <Loader type="Puff" color="#334D52" height={30} width={30} />
                }
                highlightOnHover
                pagination
              />
            </div>
            {/* pdf modal
             */}
            {uploadPdf && (
              <DropzoneDialogBase
                dialogTitle={dialogTitle()}
                acceptedFiles={["application/pdf"]}
                fileObjects={fileObjects}
                cancelButtonText={"Cancel"}
                submitButtonText={"Submit"}
                maxFileSize={5000000}
                open={pdfModal}
                onAdd={(newFileObjs) => {
                  setFileObjects(newFileObjs);
                }}
                onDelete={(deleteFileObj) => {
                  setFileObjects([]);
                }}
                onClose={() => setUploadPdf(false)}
                onSave={onSubmitPdfs}
                showPreviews={true}
                showFileNamesInPreview={true}
                filesLimit={1}
              />
            )}

            {/* ADD pdf modal open */}
            {isType ? (
              <Dialog
                fullScreen
                open={isType}
                onClose={handleAddInvoice}
                // TransitionComponent={Transition}
              >
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleAddInvoice}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
                <List>
                  {isType === true ? (
                    <div className="form ml-30 ">
                      <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">
                          {/* PDF name: */}PDF name:
                        </label>
                        <div className="col-lg-9 col-xl-6">
                          <div>
                            <input
                              type="text"
                              placeholder="Eintreten PDF Name"
                              className={`form-control form-control-lg form-control-solid `}
                              id="name"
                              name="name"
                              value={inputValue?.name}
                              onChange={(e) => {
                                handleOnChange(e);
                              }}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors}
                          </span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-xl-3 col-lg-3 col-form-label">
                          {/* PDF name: */}Taste name:
                        </label>
                        <div className="col-lg-9 col-xl-6">
                          <div>
                            <input
                              type="text"
                              placeholder="Eintreten PDF Button Name"
                              className={`form-control form-control-lg form-control-solid `}
                              id="Buttonname"
                              name="Buttonname"
                              value={ButtonValue?.Buttonname}
                              onChange={(e) => {
                                handleOnChange(e);
                              }}
                            />
                          </div>
                          <span
                            style={{
                              color: "red",
                              top: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {btnErr}
                          </span>
                        </div>
                      </div>

                      <div
                        className="d-flex align-items-center justify-content-center"
                        // onClick={() => {setPdfModal(true);}}
                      >
                        <button
                          className="btn btn-warning mr-2"
                          onClick={PdfValidation}
                        >
                          {/* <span>Add PDF & Submit</span> */}
                          <span>PDF hinzufügen & einreichen</span>
                          {loading && (
                            <span className="mx-3 spinner spinner-white"></span>
                          )}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </List>
              </Dialog>
            ) : null}
            {/* Add PDF modal close? */}
          </div>
        </List>
      </Dialog>
    </>
  );
};

export default LessionPdfs;
